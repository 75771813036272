import React, {useState, useEffect, useCallback} from "react";
import {
  Box,
} from "@mui/material";

import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  addDoc,
} from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase-config";
import { useSnackbar } from "notistack";
import {WeeklyOrdersDataGrid} from "../components/WeeklyOrdersDataGrid";


const initialGroupTracker = {
  1:0,
  2:0,
  3:0,
  4:0,
  5:0,
  6:0,
  7:0,
  8:0,
  9:0,
  10:0,
  11:0,
}

export default function DuplicateWeeklyOrder({claims}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [weekDate, setWeekDate] = useState(null);
  const [startWeekDay, setStartWeekDay] = useState(1);
  const [remaining, setRemaining] = useState(0);
  const [, setErrors] = useState({});
  const [groupTracker, setGroupTracker] = useState(initialGroupTracker);


  const removePlacementGaps = useCallback(async (currentRows, placement, group) => {
    const rowPlacement = placement
    const rowGroup = group
    const tempRows = currentRows.map((row) => {
      if (row.group === rowGroup) {
        if (row.placement > rowPlacement) {
          row.placement = row.placement - 1;
        }
      }
      return row;
    });
    setGroupTracker(gt=>{
      return {
        ...gt,
        [rowGroup]: gt[rowGroup] - 1
      }
    });
    return tempRows;
  }, [])


  const loadDataGrid = useCallback(async () => {
    setListLoading(true);
    const individualCutsQuery = query(
      collection(db, `/accounts/${claims.accountID}/individualCuts`),
      where("orderListID", "==", id)
    );
    const individualCutsResults = await getDocs(individualCutsQuery);
    let allIndividualCuts = [];
    individualCutsResults.forEach((item) => {
      const data = {
        id: item.id,
        ...item.data(),
        group: item.data().group,
        weekStartDate: new Date(
          item.data().weekStartDate.seconds * 1000
        ).toLocaleString("en-US"),
        created: new Date(item.data().created.seconds * 1000).toLocaleString(
          "en-US"
        ),
        placement: item.data().placement,
      };
      if (item.data().group) {
        setGroupTracker((gt)=>{
          return {
            ...gt,
            [item.data().group]: gt[item.data().group] + 1
          }
        });
      }
      allIndividualCuts.push(data);
    });
    let preventDuplicates = []
    let duplicateItems = []
    let tempRows = [];
    for (const cutItem of allIndividualCuts) {
      const docRef = doc(db, `/accounts/${claims.accountID}/clients/${cutItem.clientID}`);
      const clientDoc = await getDoc(docRef);
      if (clientDoc.data().active) {
        if (!preventDuplicates.includes(cutItem.clientID)) {
          preventDuplicates.push(cutItem.clientID);
          tempRows.push({
            id: clientDoc.id,
            clientID: clientDoc.id,
            firstName: clientDoc.data().firstName,
            lastName: clientDoc.data().lastName,
            group: cutItem.group,
            phone: clientDoc.data().phone,
            email: clientDoc.data().email,
            price: clientDoc.data().price,
            address: clientDoc.data().customizedAddress ? clientDoc.data().customizedAddress : clientDoc.data().address,
            placement: cutItem.placement
          });
        } else {
          duplicateItems.push(cutItem);
        }
      }
    }
    // sort to ensure that the highest placements are adjusted first
    duplicateItems.sort((a, b) => b.placement - a.placement);
    for (const item of duplicateItems) {
       tempRows = await removePlacementGaps(tempRows, item.placement, item.group)
    }
    await setTableData([...tempRows]);

    setListLoading(false);
  }, [claims.accountID, id, removePlacementGaps])

  useEffect( () => {
    const getStartWeekDayDoc = async () => {
      const docRef = doc(db, `accounts/${claims.accountID}/settings/startDayOfWeek`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setStartWeekDay([
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ].indexOf(docSnap.data().startDayOfWeek));
      }
    };
    if (claims?.accountID) {
      void loadDataGrid();
      void getStartWeekDayDoc(claims.accountID);
      setGroupTracker(initialGroupTracker);
    }
  }, [claims.accountID, navigate, loadDataGrid]);

  // const saveClick = () => {
  //   const remaining = tableData.filter((e) => e.group === null);
  //   if (remaining.length) {
  //     setRemaining(remaining.length);
  //     return;
  //   }
  //   saveWeeklyOrder();
  // };

  const checkIfOrderListForThatWeekExists = async () => {
    const nextDay = new Date(weekDate.getTime() + 60 * 60 * 24 * 1000);
    const prevDay = new Date(weekDate.getTime() - 60 * 60 * 24 * 1000);
    const q = query(
      collection(db, `/accounts/${claims.accountID}/orderList`),
      where("weekStartDate", "<", nextDay),
      where("weekStartDate", ">", prevDay)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.size !== 0
  };

  const saveWeeklyOrder = async () => {
    try {
      if (!weekDate) {
        setErrors({ date: "Select Week for order" });
        return;
      }
      setSaving(true);
      const isOrderListExistingForThatWeek = await checkIfOrderListForThatWeekExists()
      if (isOrderListExistingForThatWeek) {
        enqueueSnackbar("Order List for that week already exists", { variant: "error" });
        setSaving(false);
        setRemaining(0)
        return
      }
      const orderRef = collection(db, `/accounts/${claims.accountID}/orderList`);
      const OrderData = await addDoc(orderRef, {
        weekStartDate: weekDate,
        created: serverTimestamp(),
        totalOrder: tableData?.length || 0,
      });
      if (OrderData?.id) {
        try {
          for (const row of tableData) {
            const collectionRef = collection(db, `/accounts/${claims.accountID}/individualCuts`);
            let temp = {
              cut: false,
              employees: [],
              cutDate: null,
              clientID: row.clientID,
              weekStartDate: weekDate,
              created: serverTimestamp(),
              group: row.group,
              price: row.price,
              orderListID: OrderData.id,
              placement: row.placement,
            };
            await addDoc(collectionRef, temp)
          }
          enqueueSnackbar("Order created", { variant: "success" });
          setSaving(false);
          navigate(`/order-list`);
        } catch(e){
          console.log("error", e);
          setSaving(false);
          enqueueSnackbar("Error in order creation", { variant: "error" });
        }
      }
    } catch (error) {
      enqueueSnackbar("Error in order creation", { variant: "error" });
    }
  };


  const onWeekSelection = useCallback((dateStartOfWeek) => {
    // passed up from WeekSelector
    setWeekDate(dateStartOfWeek);
  }, [])



  return (
    <Box>

      <WeeklyOrdersDataGrid
        tableData={tableData}
        setTableData={setTableData}
        groupTracker={groupTracker}
        setGroupTracker={setGroupTracker}
        listLoading={listLoading}
        remaining={remaining}
        setRemaining={setRemaining}
        saving={saving}
        saveWeeklyOrder={saveWeeklyOrder}
        startWeekDay={startWeekDay}
        onWeekSelection={onWeekSelection}
      />
    </Box>
  );
}
