import React, {useState} from "react";
import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth} from "../firebase-config";
import {Box, Button, CircularProgress, Container, TextField} from "@mui/material";
import Joi from "joi";
import { useNavigate } from "react-router-dom";

const registerSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }),
  password: Joi.string().min(8).required(),
});

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = registerSchema.validate(formData);
    const { error } = result;
    setSaving(true);
    if (!error) {
      setErrors({});
      handleRegister();
    } else {
      setSaving(false);
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        switch(item.type) {
          case "string.email":
            errorData[name] = "Invalid email";
            break;
          case "string.empty":
            errorData[name] = "This field is required";
            break;
          case "string.min":
            errorData[name] = `Field must be at least ${item.context.limit} characters`;
            break;
          default:
            errorData[name] = item.message;
        }
      }
      setErrors(errorData);
    }
  };

  const handleRegister = () => {
    createUserWithEmailAndPassword(auth, formData.email, formData.password)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log(error.code)
        console.log(error.message)
        switch (error.code) {
          case "auth/email-already-in-use":
            setErrors({...errors, email: "There is an existing account for that email address."});
            break;
          default:
            return;
        }
        setSaving(false);
      });
  };

  const handleChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
    setErrors({ ...errors, [field]: null });
  };

  return (
    <Container maxWidth="xs">
      <form onSubmit={handleSubmit} noValidate>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box>
            <h1>Grass Pro Register</h1>
          </Box>
          <Box sx={{ mt: 1 }}>
            <TextField
              required
              id="email"
              label="Email"
              placeholder="Email"
              type="email"
              name="email"
              autoComplete={"username"}
              value={formData.email}
              onChange={(e) => handleChange(e.target.value, "email")}
              error={Boolean(errors.email)}
              helperText={errors.email || ""}
              fullWidth
            />
            <TextField
              required
              id="password"
              label="Password"
              placeholder="Password"
              type="password"
              name="password"
              autoComplete={"new-password"}
              value={formData.password}
              onChange={(e) =>
                handleChange(e.target.value, "password")
              }
              error={Boolean(errors.password)}
              helperText={errors.password || ""}
              fullWidth
              sx={{
                marginTop: 3,
              }}
            />
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              disabled={saving}
              sx={{
                marginTop: 3,
                py:1.5,
              }}

            >
              Register
              {saving && <CircularProgress color="primary" size={20} />}
            </Button>
          </Box>
        </Box>
      </form>
    </Container>
  );
}

export default Register;
