import React, {useCallback, useEffect, useState} from "react";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc, doc,
} from "firebase/firestore";
import { db } from "../firebase-config";

import { Box, CircularProgress } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

export default function CutByEmployee({ employeeID, claims }) {
  const [tableData, setTableData] = useState([]);
  const apiRef = useGridApiRef();
  const [listLoading, setListLoading] = useState(true);

  const loadDataGrid = useCallback(async () => {
    setListLoading(true);
    const individualCutsQueryByEmployee = query(
      collection(db, `/accounts/${claims.accountID}/individualCuts`),
      where("employees", "array-contains", employeeID),
      where("cut", "==", true),
    );
    const employeeCutsResults = await getDocs(individualCutsQueryByEmployee);
    let allEmployeeCutsData = [];
    employeeCutsResults.forEach((doc) => {
      allEmployeeCutsData.push({ id: doc.id, ...doc.data()});
    });
    let tempRows = [];
    for (const individualCut of allEmployeeCutsData) {
      const clientRef = doc(db, `/accounts/${claims.accountID}/clients/${individualCut.clientID}`);
      const clientDoc = await getDoc(clientRef);
      const data = {
        id: individualCut.id,
        firstName: clientDoc.data().firstName,
        lastName: clientDoc.data().lastName,
        group: individualCut.group,
        cutDate: new Date(individualCut.cutDate.seconds * 1000).toLocaleDateString("en-US"),
        phone: clientDoc.data().phone,
        email: clientDoc.data().email,
        address: clientDoc.data().address || "",
        created: new Date(individualCut.created.seconds * 1000).toLocaleDateString("en-US"),
      };
      tempRows.push(data);
    }
    setTableData([...tempRows]);
    setListLoading(false);
  }, [employeeID, claims.accountID]);

  useEffect( () => {
    if (employeeID && claims?.accountID) {
      void loadDataGrid();
    } else {
      setListLoading(false);
    }
    // Cleanup:
    return () => {
      setListLoading(true);
      setTableData([])
    };
  }, [loadDataGrid, claims.accountID, employeeID]);



const columns = [
    { field: "cutDate", headerName: "Cut Date", width: 100, editable: false },
    { field: "address", headerName: "Address", width: 225, editable: false},
    { field: "firstName", headerName: "First Name", width: 125, editable: false, },
    { field: "lastName", headerName: "Last Name", width: 125, editable: false },
  ];

  return (
    <Box
      sx={{
        p: 3,
      }}
    >
      <Box
        sx={{
          height: 600,
          maxWidth: "600px",
          mx: "auto",
          my: "15px",
        }}
      >
        {!listLoading ? (
          <DataGridPro
            apiRef={apiRef}
            editMode="row"
            rows={tableData}
            columns={columns}
          />
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
}
