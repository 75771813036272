import React, {useState, useEffect, useCallback} from "react";
import {
  Grid,
} from "@mui/material";

import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  where, updateDoc,
} from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase-config";
import { useSnackbar } from "notistack";
import {WeeklyOrdersDataGrid} from "../components/WeeklyOrdersDataGrid";

const initialGroupTracker = {
  1:0,
  2:0,
  3:0,
  4:0,
  5:0,
  6:0,
  7:0,
  8:0,
  9:0,
  10:0,
  11:0,
}

export default function EditWeeklyOrder({claims}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const [groupTracker, setGroupTracker] = useState(initialGroupTracker);

  const extractDataFromQuery = async (queryRef) => {
    const results = await getDocs(queryRef)
    let resultsData = []
    results.forEach((doc)=> {
      resultsData.push({...doc.data(), id: doc.id})
    })
    return resultsData
  }

  const loadDataGrid = useCallback(async () => {
    setListLoading(true);
    const tempGroupTracker = initialGroupTracker
    const individualCutsQuery = query(
      collection(db, `/accounts/${claims.accountID}/individualCuts`),
      where("orderListID", "==", id)
    );
    const clientsActiveQuery = query(
      collection(db, `accounts/${claims.accountID}/clients`),
      where("active", "==", true)
    )
    const individualCutsData = await extractDataFromQuery(individualCutsQuery)
    const clientsActiveData = await extractDataFromQuery(clientsActiveQuery)
    let tempRows = [];
    const cutsWithoutClients = [] // missing because not active or deleted
    const clientsActiveObj = {}
    for (const client of clientsActiveData) {
      clientsActiveObj[client.id] = client
    }
    for (const cut of individualCutsData) {
      const exists = Object.hasOwn(clientsActiveObj, cut.clientID)
      if (exists) {
        const clientData = clientsActiveObj[cut.clientID]
        tempRows.push({
          id: cut.id,
          firstName: clientData.firstName,
          lastName: clientData.lastName,
          group: cut.group,
          cut: cut.cut,
          phone: clientData.phone,
          email: clientData.email,
          address: clientData.customizedAddress ? clientData.customizedAddress : clientData.address,
          placement: cut.placement,
          clientID: cut.clientID,
        })
        tempGroupTracker[cut.group] += 1
      } else {
        cutsWithoutClients.push(cut)
      }
    }
    const promises = []
    for (const cut of cutsWithoutClients) {
      const docRef = doc(db, `/accounts/${claims.accountID}/clients/${cut.clientID}`);
      promises.push(getDoc(docRef).then((clientDoc) => {
        if (clientDoc.exists()) {
          const data = {
            id: cut.id,
            firstName: clientDoc.data().firstName,
            lastName: clientDoc.data().lastName,
            group: cut.group,
            cut: cut.cut,
            phone: clientDoc.data().phone,
            email: clientDoc.data().email,
            address: clientDoc.data().customizedAddress ? clientDoc.data().customizedAddress : clientDoc.data().addresss,
            placement: cut.placement,
            clientID: cut.clientID,
          };
          tempGroupTracker[cut.group] += 1
          tempRows.push(data);
        } else {
          const data = {
            id: cut.id,
            firstName: "Deleted",
            lastName: "Deleted",
            group: cut.group,
            cut: cut.cut,
            phone: "Deleted",
            email: "Deleted",
            address: "Deleted",
            placement: cut.placement,
            clientID: cut.clientID,
          };
          tempGroupTracker[cut.group] += 1
          tempRows.push(data);
        }
        }
      ))
    }
    await Promise.all(promises)
    setGroupTracker(tempGroupTracker)
    setTableData([...tempRows]);
    setListLoading(false);
  },[id, claims.accountID])


  useEffect( () => {
    console.log('usingEffect')
    if (id && claims?.accountID) {
        void loadDataGrid();
    } else {
      setListLoading(false);
    }
  }, [id, claims.accountID, navigate, loadDataGrid]);


  const saveWeeklyOrder = async () => {
    try{
      const promises = []
      for (const row of tableData) {
        const docRef = doc(db, `/accounts/${claims.accountID}/individualCuts/${row.id}`);
        promises.push(updateDoc(docRef, {
          group: row.group,
          placement: row.placement
        }))
      }
      await Promise.all(promises)
      setSaving(false);
      enqueueSnackbar("Order saved", { variant: "success" });
    } catch (e) {
      console.log("err", e);
      setSaving(false);
      enqueueSnackbar("Error while saving", { variant: "error" });
    }
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <WeeklyOrdersDataGrid
          tableData={tableData}
          setTableData={setTableData}
          groupTracker={groupTracker}
          setGroupTracker={setGroupTracker}
          listLoading={listLoading}
          remaining={remaining}
          setRemaining={setRemaining}
          saving={saving}
          saveWeeklyOrder={saveWeeklyOrder}
        />
      </Grid>
    </Grid>
  );
}
