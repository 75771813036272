import React, {useCallback, useEffect, useState} from "react";
import {
  Grid,
  CircularProgress,
  Button,
  TextField,
  InputAdornment,
  Box, FormControlLabel, Checkbox, Paper,
} from "@mui/material";

import {
  collection,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  addDoc,
} from "firebase/firestore";
import Joi from "joi";
import { db } from "../firebase-config";
import { useSnackbar } from "notistack";
import { useParams, useNavigate } from "react-router-dom";
import ClientNotes from "../components/ClientNotes";
import AddClientToOrder from "../components/AddClientToOrder";
import {GoogleMapsAutocomplete} from "../components/GoogleMapsAutocomplete";
import axios from "axios"

export default function ClientCreateEdit({claims, user}) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    active: true,
    doNotInvoice: false,
    address: "",
    customizedAddress: "",
    price: 0,
    customerQBID: "",
  });
  const [errors, setErrors] = useState({});
  const [listLoading, setListLoading] = useState(true);
  const [QBLoading, setQBLoading] = useState(true);
  const [isQBVerified, setIsQBVerified] = useState(false);
  const [webhookValidateQBCustomer, setWebhookValidateQBCustomer] = useState("");
  const [webhookValidationCode, setWebhookValidationCode] = useState("");
  const [saving, setSaving] = useState(false);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const clientSchema = Joi.object({
    firstName: Joi.string().min(1).max(20).required(),
    lastName: Joi.string().required(),
    email: Joi.string().email({ tlds: { allow: false } }).allow(""),
    phone: Joi.string()
      .length(10)
      .pattern(/^\d+$/)
      .required(),
    active: Joi.boolean(),
    doNotInvoice: Joi.boolean(),
    address: Joi.string().required(),
    customizedAddress: Joi.string().allow(""),
    price: Joi.number().required(),
    customerQBID: Joi.string().required(),
  });

  const getClientDoc = useCallback(async () => {
    setListLoading(true);
    const docRef = doc(db, `/accounts/${claims.accountID}/clients/${id}/`)
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setFormData({
        firstName: docSnap.data().firstName,
        lastName: docSnap.data().lastName,
        phone: docSnap.data().phone.substring(2),
        email: docSnap.data().email || "",
        address: docSnap.data().address || "",
        customizedAddress: docSnap.data().customizedAddress || "",
        active: docSnap.data().active,
        doNotInvoice: docSnap.data().doNotInvoice,
        price: docSnap.data().price.toFixed(2),
        customerQBID: docSnap.data().customerQBID,
      });
      setIsQBVerified(true)
    } else {
      enqueueSnackbar("No record found with given ID", { variant: "error" });
      navigate("/clients");
    }
    setListLoading(false);
  }, [claims.accountID, id, navigate, enqueueSnackbar])

  const getWebhookValidateQBCustomer = useCallback(async () => {
    const docRef= doc(db, `/accounts/${claims.accountID}/settings/webhooks`)
    const docSnap = await getDoc(docRef);
    if (docSnap.exists) {
      setWebhookValidateQBCustomer(docSnap.data().validateQBCustomer)
      setWebhookValidationCode(docSnap.data().QBMakeValidationCode)
    }
  }, [claims.accountID])

  useEffect(() => {
    setQBLoading(false)
    if (id && claims?.accountID) {
      void getClientDoc();
    } else if (claims?.accountID) {
      setListLoading(false);
      void getWebhookValidateQBCustomer();
    } else {
      setListLoading(false)
    }
    // Cleanup:
    return () => {
      setListLoading(true);
    };
  }, [id, claims.accountID, navigate, getClientDoc, getWebhookValidateQBCustomer]);


  const createNewClient = async () => {
    const colRef = collection(db, `/accounts/${claims.accountID}/clients`);
    console.log("formData 2", formData)

    await addDoc(colRef, {
      created: serverTimestamp(),
      ...formData,
      phone: `+1${formData.phone}`,
      price: parseFloat(parseFloat(formData.price).toFixed(2)),
    })
      .then((res) => {
        setSaving(false);
        enqueueSnackbar("Client added", { variant: "success" });
        navigate(`/client/${res.id}`);
      })
      .catch((err) => {
        console.log("err", err);
        setSaving(false);
        enqueueSnackbar("Error in client creation", { variant: "error" });
      });
  };

  const updateClient = async () => {
    const docRef = doc(db, `accounts/${claims.accountID}/clients/${id}`);
    await updateDoc(docRef, {
      ...formData,
      phone: `+1${formData.phone}`,
      price: parseFloat(parseFloat(formData.price).toFixed(2)),
    })
      .then(() => {
        setSaving(false);
        enqueueSnackbar("Client updated", { variant: "success" });
        navigate(`/client/${id}`);
      })
      .catch((err) => {
        console.log("err", err);
        setSaving(false);
        enqueueSnackbar("Error in client update", { variant: "error" });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = clientSchema.validate(formData);
    const { error } = result;
    setSaving(true);
    console.log("formData", formData);
    if (!error) {
      setErrors({});
      if (!id) {
        await createNewClient();
      } else {
        await updateClient();
      }
    } else {
      setSaving(false);
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        errorData[name] = item.message;
      }
      setErrors(errorData);
      return errorData;
    }
  };

  const verifyQB = async () => {
    setQBLoading(true);
    setErrors({});
    try {
      const response = await axios.post(webhookValidateQBCustomer, {
        customerQBID: formData.customerQBID,
        webhookValidationCode: webhookValidationCode,
      })
      setFormData({
        ...formData,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
      })
      setIsQBVerified(true)
      enqueueSnackbar("Customer verified", { variant: "success" });
    } catch(err) {
      console.log("err", err)
      setErrors({
        customerQBID:
          "Customer not found.",
      });

    }
    setQBLoading(false);
  };


  const handleChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleAddressChange = (value) => {
    setFormData(data => {
        return {...data, "address": value}
      }
    )
    setErrors({});
  }
  return (
    <div>
      <Box>
        {!listLoading ? (
          <div>
            <Box
              sx={{
                maxWidth: "976px",
                mx: "auto",
                pt: "25px",
              }}
            >
              <Grid
                container
                justifyContent={"center"}
                columnSpacing={5}
                spacing={3}
              >
                <Grid item xs={12} sm={12} md={6} lg={8}>
                  <Paper
                    elevation={3}
                    sx={{
                      maxWidth: {xs: "600px", sm: "900px"},
                      mx: "auto",
                      pt: 3,
                      pb: 3,
                      mt: "25px",
                    }}
                  >
                    <form onSubmit={handleSubmit}>
                      <Box>
                        <h2>Client Details</h2>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: 3,
                          alignItems: "center",
                        }}
                        padding={4}
                      >
                        <TextField
                          required
                          id="customerQBID"
                          label="Quickbooks Customer ID"
                          placeholder="Quickbooks Customer ID"
                          name="customerQBID"
                          value={formData.customerQBID}
                          onChange={(e) =>
                            handleChange(e.target.value, "customerQBID")
                          }
                          error={Boolean(errors.customerQBID)}
                          helperText={errors.customerQBID || ""}
                          fullWidth
                          disabled={isQBVerified}
                        />
                        { !isQBVerified && (
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={saving || isQBVerified}
                            fullWidth
                            size={"large"}
                            onClick={() => {
                              verifyQB()
                            }}
                          >
                            Validate QB ID                             {QBLoading&& (
                            <CircularProgress sx={{"color": "white", ml: 1}} size={20} />
                          )}
                          </Button>
                        )
                        }

                        <TextField
                          id="first-name"
                          label="First Name"
                          required
                          name="firstName"
                          value={formData.firstName}
                          placeholder="First Name"
                          onChange={(e) =>
                            handleChange(e.target.value, "firstName")
                          }
                          error={Boolean(errors.firstName)}
                          helperText={errors.firstName || ""}
                          fullWidth
                          disabled={!isQBVerified}
                        />
                        <TextField
                          required
                          id="last-name"
                          label="Last Name"
                          placeholder="Last Name"
                          name="lastName"
                          value={formData.lastName}
                          onChange={(e) =>
                            handleChange(e.target.value, "lastName")
                          }
                          error={Boolean(errors.lastName)}
                          helperText={errors.lastName || ""}
                          fullWidth
                          disabled={!isQBVerified}
                        />
                        <TextField
                          id="email"
                          label="Email"
                          placeholder="Email"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={(e) =>
                            handleChange(e.target.value, "email")
                          }
                          error={Boolean(errors.email)}
                          helperText={errors.email || ""}
                          fullWidth
                          disabled={!isQBVerified}
                        />
                        <TextField
                          id="phone"
                          name="phone"
                          label="Phone"
                          type="number"
                          placeholder="9988776655"
                          value={formData.phone}
                          onChange={(e) =>
                            handleChange(e.target.value, "phone")
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +1
                              </InputAdornment>
                            ),
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          fullWidth
                          error={Boolean(errors.phone)}
                          helperText={errors.phone || ""}
                          disabled={!isQBVerified}
                        />
                        <TextField
                          required
                          id="price"
                          label="Price"
                          placeholder="price"
                          name="price"
                          value={formData.price}
                          onChange={(e) =>
                            handleChange(e.target.value.replace(/[^0-9.]/g, ''), "price")
                          }
                          error={Boolean(errors.price)}
                          helperText={errors.price || ""}
                          fullWidth
                          disabled={!isQBVerified}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />

                        <GoogleMapsAutocomplete
                          disabled={!isQBVerified}
                          handleAddressChange={handleAddressChange}
                        />
                        <TextField
                          label={"Selected Address"}
                          name={`selectedAddress`}
                          id={"selectedAddress"}
                          value={formData.address}
                          InputLabelProps={formData.address ? { shrink: true } : {shrink: false}}
                          fullWidth
                          disabled
                          variant={'standard'}
                          error={Boolean(errors.address)}
                          helperText={errors.address || ""}
                          sx={{
                            px: 1,
                          }}
                        />

                        <TextField
                          id="customized-address"
                          label="Optional: Customized Address"
                          placeholder="Customized Address "
                          name="customizedAddress"
                          value={formData.customizedAddress}
                          onChange={(e) =>
                            handleChange(e.target.value, "customizedAddress")
                          }
                          error={Boolean(errors.customizedAddress)}
                          helperText={errors.customizedAddress || ""}
                          fullWidth
                          disabled={!isQBVerified}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formData.doNotInvoice}
                              onChange={(e) =>
                                handleChange(e.target.checked, "doNotInvoice")
                              }
                            />
                          }
                          label="Don't Invoice This Client"
                        />
                        {errors.doNotInvoice && (
                          <Box className="alert alert-danger">
                            {errors.doNotInvoice}
                          </Box>
                        )}
                        {id && (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formData.active}
                                  onChange={(e) =>
                                    handleChange(e.target.checked, "active")
                                  }
                                />
                              }
                              label="Active"
                            />
                            {errors.active && (
                              <Box className="alert alert-danger">
                                {errors.active}
                              </Box>
                            )}
                          </>
                        )}
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          disabled={saving || !isQBVerified}
                          fullWidth
                          size={"large"}
                        >
                          {id ? "Save" : "Create"}
                          {saving && (
                            <CircularProgress color="primary" size={20} />
                          )}
                        </Button>
                      </Box>
                    </form>
                  </Paper>
                </Grid>
                {id && (
                  <Grid item xs={12} sm={12} md={5} lg={4}
                        sx={{
                          mb: {
                            xs: 3,
                            sm: 3,
                          },
                          mt: {
                            xs: 3,
                            sm: 3,
                            md: 0,
                          }
                        }}>
                    <AddClientToOrder clientID={id} claims={claims} user={user}/>
                  </Grid>
                )}

                {id && (
                  <Grid item xs={12} sm={12} md={12} lg={12}
                        sx={{
                          mt: 3,
                          mb: 5,
                        }}
                  >
                    <ClientNotes clientID={id} claims={claims} user={user}/>
                  </Grid>
                )}

              </Grid>
            </Box>
          </div>
        ) : (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            padding={4}
            justifyContent="center"
          >
            <CircularProgress color="primary" size={50} />
          </Grid>
        )}
      </Box>
    </div>
  );
}
