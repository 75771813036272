import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Tooltip} from "@mui/material";

function LinearProgressWithLabel(props) {
    const {value, valueBuffer} = props
    let switcher = false
    if (value > valueBuffer) {
        switcher = true
    }
    const bgColorBar2 = () => {
        if (valueBuffer > value){
            return "#DC143C"
        }
        if (valueBuffer < value){
            return "rgb(53,164,22)"
        }
        // return valueBuffer > value ? "#DC143C" : ""
    }
    const bgColorBar1 = () => {
        if (valueBuffer > value){
            return "#1976d2"
        }
        if (valueBuffer < value){
            return "#1976d2"
        }
        // return valueBuffer > value ? "#DC143C" : ""
    }

    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{minWidth: 35, pr:1}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
            <Box sx={{width: '100%', mr: 1}}>
                <Tooltip title={`${value.toFixed(0)}%`}>
                    <LinearProgress variant="buffer" value={switcher ? valueBuffer : value} valueBuffer={switcher ? value : valueBuffer} sx={{
                        '& .MuiLinearProgress-dashed': {
                            width: 0
                        },
                        '& .MuiLinearProgress-bar2Buffer': {
                            backgroundColor: bgColorBar2(),
                        },
                        '& .MuiLinearProgress-bar1Buffer': {
                            backgroundColor: bgColorBar1(),
                        },
                    }
                    }/>

                </Tooltip>
            </Box>
        </Box>
    );
}

export default function LinearProgressLabelAndBuffer({progress, buffer}) {
    if (isNaN(progress)) {
        progress = 0.0001
    }
    if (progress === 0) {
        progress = 0.0001
    }
    return (
        <Box sx={{width: '100%'}}>
            {progress && buffer &&
                <LinearProgressWithLabel value={progress} valueBuffer={buffer}/>
            }
        </Box>
    );
}
