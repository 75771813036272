import React, {useState, useEffect, useCallback} from "react";
import {TextField, Box, LinearProgress} from "@mui/material";

import {
  DataGridPro,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { collection, getDoc, getDocs, query, where, doc } from "firebase/firestore";
import { db } from "../firebase-config";
import {DateRangePicker} from "@mui/x-date-pickers-pro";
import {endOfDay} from "date-fns";
import Divider from "@mui/material/Divider";

const payPercentageToEmployees = .35

function CustomFooterTotalComponent(props) {
  return (
    <Box>
      <Divider/>
    <Box sx={{ padding: 2, display: "flex", justifyContent: "space-evenly", flexDirection: "column" }}>
      <Box>
        Total Revenue: ${props.totalPricePerCut.toFixed(2)}
      </Box>
      <Box>
        Net Revenue: ${(props.totalPricePerCut - (props.totalPricePerCut * payPercentageToEmployees)).toFixed(2)}
      </Box>
      <Box>
        Total Paid To Employees: ${(props.totalPricePerCut * payPercentageToEmployees).toFixed(2)}
      </Box>
      <Box>
        Total Pay Per Employee: ${props.totalPerEmployee.toFixed(2)}
      </Box>
    </Box>
    </Box>

  );
}


export default function CutsFromDateRange({claims}) {
  const apiRef = useGridApiRef();
  const [tableData, setTableData] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [totalPerEmployee, setTotalPerEmployee] = useState(0);
  const [totalPricePerCut, setTotalPricePerCut] = useState(0);
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: "employees",
        operatorValue: "isNotEmpty",
      }
    ]
  });

  const loadDataGrid = useCallback(async () => {
    console.log('loadDataGrid');
    setListLoading(true);
    const employeesRef = query(
        collection(db, `accounts/${claims.accountID}/employees/`)
    );
    const employeesDocs = await getDocs(employeesRef);
    const employeeData = [];
    employeesDocs.forEach((doc) => {
      employeeData.push({...doc.data(), id: doc.id});
    })
    const individualCutsQuery = query(
        collection(db, `/accounts/${claims.accountID}/individualCuts`),
        where("cutDate", ">=", dateRange[0]),
        where("cutDate", "<=", endOfDay(dateRange[1]))
    );
    const individualCutsResults = await getDocs(individualCutsQuery);
    let individualCutsDocs = [];
    individualCutsResults.forEach((doc) => {
      const data = {
        id: doc.id,
        ...doc.data(),
      };
      individualCutsDocs.push(data);
    });

    // Create a set to store unique client IDs
    const uniqueClientIDs = new Set(individualCutsDocs.map(docData => docData.clientID));

    // Create an array of promises for fetching client documents based on unique client IDs
    const clientDocPromises = Array.from(uniqueClientIDs).map(clientID => {
      const clientRef = doc(db, `/accounts/${claims.accountID}/clients/${clientID}`);
      return getDoc(clientRef);
    });

    // Wait for all promises to resolve and get an array of client documents
    const clientDocs = await Promise.all(clientDocPromises);

    // Create an object to store client documents with client ID as the key
    const clientDocsMap = {};
    clientDocs.forEach(clientDoc => {
      clientDocsMap[clientDoc.id] = clientDoc;
    });

    let tempRows = [];
    for (let i = 0; i < individualCutsDocs.length; i++) {
      const docData = individualCutsDocs[i];
      const clientDoc = clientDocsMap[docData.clientID]; // Retrieve client document using clientDocsMap
      const clientData = clientDoc.data();

      let employeeNames = [];
      for (const employeeID of docData.employees) {
        const employee = employeeData.find((employee) => employee.id === employeeID);
        if (employee) {
          employeeNames.push(employee.firstName + " " + employee.lastName);
        }
      }
      const totalPayToAllEmployees = clientData.price * payPercentageToEmployees;
      tempRows.push({
        id: docData.id,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        group: docData.group,
        phone: clientData.phone,
        email: clientData.email,
        address: clientData.customizedAddress ? clientData.customizedAddress : clientData.address,
        created: new Date(docData.created.seconds * 1000).toLocaleString("en-US"),
        cutDate: new Date(docData.cutDate.seconds * 1000).toLocaleString("en-US"),
        clientID: docData.clientID,
        employees: employeeNames.join(", "),
        price: docData.price,
        totalPayToAllEmployees: totalPayToAllEmployees,
        payPerEmployee: totalPayToAllEmployees / docData.employees.length,
      });
    }
    setTableData([...tempRows]);
    setListLoading(false);
  }, [dateRange, claims.accountID]);

  useEffect( () => {
    if (dateRange[0] && dateRange[1] && claims?.accountID) {
      void loadDataGrid();
    } else {
      setListLoading(false);
    }
  }, [dateRange, loadDataGrid, claims.accountID]);


  const columns = [
    {
      field: "address",
      headerName: "Address",
      width: 150,
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 100,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 100,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 125,
    },
    {
      field: "email",
      headerName: "Email",
      width: 175,
    },
    {
      field: "cutDate",
      headerName: "Cut Date",
      width: 200,
    },
    {
      field: "employees",
      headerName: "Employees",
      width: 200,
    },
    {
      field: "price",
      headerName: "Price Per Cut",
      width: 125,
      valueFormatter: (params) => {
        if (typeof params.value !== "undefined") {
          return `$${params.value.toFixed(2)}`;
        }
        return "";
      },
    },
    {
      field: "totalPayToAllEmployees",
      headerName: "Total Pay to All Employees",
      width: 190,
      valueFormatter: (params) => `$${params.value.toFixed(2)}`,
    },
    {
      field: "payPerEmployee",
      headerName: "Pay Per Employee",
      width: 150,
      valueFormatter: (params) => `$${params.value.toFixed(2)}`,
    },
  ]

  return (
    <Box >
      <Box sx={{
        display: "flex",
        justifyContent: 'left',
        columnGap: 2,
        p:3,
        pb:0,
      }}>
        <DateRangePicker
          startText="Start Date"
          endText="End Date"
          value={dateRange}
          calendars={2}
          onChange={(newValue) => {
            setDateRange(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} autoComplete='off'/>
              <Box sx={{ mx: 1 }}>  </Box>
              <TextField {...endProps} autoComplete='off'/>
            </React.Fragment>
          )}
        />
      </Box>
      <Box
        sx={{
          p: 3
        }}
      >
        <Box
          sx={{
            height: 650,
            maxWidth: "100%",
            mx: "auto",
          }}
        >
        <DataGridPro
        apiRef={apiRef}
        columns={columns}
        rows={tableData}
        loading={listLoading}
        components={{
          LoadingOverlay: LinearProgress,
          Footer: CustomFooterTotalComponent

        }}
        componentsProps={{
          footer: { totalPerEmployee, totalPricePerCut }
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        onStateChange={(state) => {
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [id, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(id);
            }
          }
          // console.log(visibleItems);
          const res = tableData.filter((item) => visibleItems.includes(item.id));
          const totalEmployee = res
            .map((item) => item.payPerEmployee)
            .reduce((a, b) => a + b, 0);
          // console.log(totalEmployee);
          setTotalPerEmployee(totalEmployee);
          const totalPrice = res
            .map((item) => item.price)
            .reduce((a, b) => a + b, 0);
          setTotalPricePerCut(totalPrice);
        }}

        />
        </Box>
      </Box>
    </Box>
  );
}
