import React, {useCallback, useEffect, useState} from 'react';
import { collection, getDoc, getDocs, query, where, orderBy, limit, updateDoc, serverTimestamp, Timestamp, doc } from 'firebase/firestore';
import { db } from '../firebase-config';
import {Paper, Card, CardContent, Typography, CircularProgress, Tooltip} from '@mui/material';
import {useNavigate} from "react-router-dom";

const PastDueCuts = ({ claims }) => {
    const [pastDueClients, setPastDueClients] = useState([]);
    const accountID = claims.accountID
    const navigate = useNavigate()
    const [listLoading, setListLoading] = useState(true);

    const updatePastDueClients = useCallback(async () => {
        const clientsRef = collection(db, `/accounts/${accountID}/clients`);
        const individualCutsRef = collection(db, `/accounts/${accountID}/individualCuts`);

        // Get all active clients
        const activeClientsQuery = query(clientsRef, where('active', '==', true));
        const activeClientsSnapshot = await getDocs(activeClientsQuery);

        // Create an array of promises for fetching the most recent individual cut for each client
        const clientCutsPromises = activeClientsSnapshot.docs.map((clientDoc) => {
            const clientID = clientDoc.id;
            const clientCutsQuery = query(individualCutsRef,
                where('clientID', '==', clientID),
                where('cutDate', '!=', null),
                orderBy('cutDate', 'desc'),
                limit(1));
            return getDocs(clientCutsQuery);
        });

        // Wait for all promises to resolve
        const clientCutsSnapshots = await Promise.all(clientCutsPromises);

        // Process the results to find past due clients
        const updatedPastDueClients = [];
        const nineDaysAgo = new Date();
        nineDaysAgo.setDate(nineDaysAgo.getDate() - 9);
        clientCutsSnapshots.forEach((clientCutsSnapshot, index) => {
            if (!clientCutsSnapshot.empty) {
                const lastCut = clientCutsSnapshot.docs[0].data();
                const cutDate = lastCut.cutDate.toDate(); // Convert to JavaScript Date

                // Check if the cut is older than 9 days
                if (cutDate < nineDaysAgo) {
                    const clientData = activeClientsSnapshot.docs[index].data();
                    const clientID = activeClientsSnapshot.docs[index].id;
                    updatedPastDueClients.push({ ...clientData, lastCutDate: cutDate, id: clientID });
                }
            }
        });

        // Update the pastDue document
        const pastDueDocRef = doc(db, `/accounts/${accountID}/analytics/pastDueCuts`);
        await updateDoc(pastDueDocRef, {
            updated: serverTimestamp(),
            pastDueClients: updatedPastDueClients,
        });
        const pastDueDocSnapshot = await getDoc(pastDueDocRef);
        setPastDueClients(pastDueDocSnapshot.data().pastDueClients);
    }, [accountID])

    useEffect(() => {
        const fetchData = async () => {
            setListLoading(true)
            const pastDueDocRef = doc(db, `/accounts/${accountID}/analytics/pastDueCuts`);
            const pastDueDocSnapshot = await getDoc(pastDueDocRef);

            if (!pastDueDocSnapshot.exists() || (Timestamp.now().seconds - pastDueDocSnapshot.data().updated.seconds) > 43200) {
                // If the document doesn't exist or is older than 12 hours, update the past due clients
                await updatePastDueClients();
            } else {
                setPastDueClients(pastDueDocSnapshot.data().pastDueClients);
            }
            setListLoading(false)
        };
        if (accountID) {
            fetchData();
        }
    }, [accountID, updatePastDueClients]);

    return (
        <Paper
            elevation={10}
            sx={{
                p: 1,
                pt: 0,
                m: 2,
                mt: 4,
                width: 360,
                overflow: 'auto',
                height: 750,
            }}
        >
            <Tooltip title="Calculated every 12 hours. Active clients which haven't been cut in the past 9 days.">
                <Typography variant={"h5"} sx={{mt: 0, pt: 2, mb: 2, position: "sticky", top: 0, backgroundColor: "white", zIndex: 1}}>
                    Cuts Past Due
                </Typography>
            </Tooltip>
            {listLoading && (
                <CircularProgress />
            )}

            {pastDueClients.length > 0 && pastDueClients.sort((a, b) => a.lastCutDate - b.lastCutDate).map((client, index) => (
                <Card key={index} sx={{ mb: 1, mx: 1 }} elevation={4}>
                    <CardContent onClick={() => {navigate(`/client/${client.id}`)}}>
                        <Typography variant="h7">{client.address}</Typography>
                        <Typography variant="body2">Last Cut: {new Date(client.lastCutDate.seconds * 1000).toLocaleDateString("en-US")}</Typography>
                    </CardContent>
                </Card>
            ))}
        </Paper>
    );
};

export default PastDueCuts;
