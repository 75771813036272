import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  TextField,
  Box, FormControl, InputLabel, Select, MenuItem,
} from "@mui/material";

import {doc, getDoc, setDoc} from "firebase/firestore";
import Joi from "joi";
import { db } from "../../../firebase-config";
import { useSnackbar } from "notistack";
import axios from "axios";

const quickbooksSchema = Joi.object({
  DescriptionOnly: Joi.string().required().allow(""),
  ItemRefName: Joi.string().required(),
  TaxCodeRefName: Joi.string().required(),
  ItemRef: Joi.number().min(1).required(),
  TaxCodeRef: Joi.number().min(1).required(),
});

const initialState = {
  DescriptionOnly: "",
  ItemRef: 0,
  ItemRefName: "",
  TaxCodeRef: 0,
  TaxCodeRefName: "",
}

export default function Quickbooks({ claims }) {
  const [errors, setErrors] = useState({});
  const [listLoading, setListLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [taxCodeValue, setTaxCodeValue] = useState("");
  const [taxCodeOptions, setTaxCodeOptions] = useState([]);
  const [QBITemValue, setQBITemValue] = useState("");
  const [QBItemOptions, setQBItemOptions] = useState([]);
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    const controller = new AbortController();
    const getQuickbooksSettings = async (accountID) => {
      setListLoading(true);
      const docRef = doc(db, `/accounts/${accountID}/settings/quickbooks`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        if (docSnap.data().TaxCodeRef && docSnap.data().TaxCodeRefName){
          setTaxCodeOptions([{Id: docSnap.data().TaxCodeRef, name: docSnap.data().TaxCodeRefName }]);
          setTaxCodeValue(docSnap.data().TaxCodeRef);
        }
        if (docSnap.data().ItemRef && docSnap.data().ItemRefName) {
          setQBItemOptions([{Id: docSnap.data().ItemRef, name: docSnap.data().ItemRefName }]);
          setQBITemValue(docSnap.data().ItemRef);
        }
        setFormData({
          DescriptionOnly: docSnap.data().DescriptionOnly || "",
          ItemRef: docSnap.data().ItemRef || 0,
          ItemRefName: docSnap.data().ItemRef || "",
          TaxCodeRef: docSnap.data().TaxCodeRef || 0,
          TaxCodeRefName: docSnap.data().TaxCodeRefName || "",
        });
      }
      const docWebhookRef = doc(db, `/accounts/${claims.accountID}/settings/webhooks`);
      const docWebhookSnap = await getDoc(docWebhookRef);
      if (docWebhookSnap.exists()) {
        const webhook = docWebhookSnap.data().getItemRefTaxCodeRef;
        const validationCode = docWebhookSnap.data().QBMakeValidationCode;
        try {
          const response = await axios.post(webhook, {
            validationCode
          }, {
            signal: controller.signal
          });
          for (const item of response.data) {
            if (item?.QueryResponse?.TaxCode){
              setTaxCodeOptions(item.QueryResponse.TaxCode);
            } else if (item?.QueryResponse?.Item){
              setQBItemOptions(item.QueryResponse.Item);
            }
          }
        } catch (e) {
          if (e.message !== "canceled") {
            console.log(e)

          }
        }
      }
      setListLoading(false);
    };
    if (claims.accountID) {
      void getQuickbooksSettings(claims.accountID);
    } else {
      setListLoading(false);
    }
    return ()=> {
      controller.abort()
      setFormData(initialState)
      setQBItemOptions([])
      setQBITemValue("")
      setTaxCodeOptions([])
      setTaxCodeValue("")
      setSaving(false)
      setListLoading(false)
    }
  }, [claims.accountID]);

  const updateQuickBooksSettings = async () => {
    const docRef = doc(db, `/accounts/${claims.accountID}/settings/quickbooks`);
    await setDoc(docRef, {
      ...formData,
    }, { merge: true })
      .then(() => {
        // console.log("res", res);
        setSaving(false);
        enqueueSnackbar("Detail saved", { variant: "success" });
      })
      .catch((err) => {
        console.log("err", err);
        setSaving(false);
        enqueueSnackbar("Error in Detail update", { variant: "error" });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = quickbooksSchema.validate(formData);
    const { error } = result;
    setSaving(true);
    if (!error) {
      setErrors({});
      await updateQuickBooksSettings();
    } else {
      setSaving(false);
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        errorData[name] = item.message;
      }
      setErrors(errorData);
      return errorData;
    }
  };

  const onTaxCodeSelect = (e) => {
    setTaxCodeValue(e.target.value);
    const {Name: name} = taxCodeOptions.find(item => item.Id === e.target.value);
    setFormData({
      ...formData,
      TaxCodeRef: e.target.value,
      TaxCodeRefName: name,
    });
  };
  const onQBItemSelect = (e) => {
    setQBITemValue(e.target.value);
    const {Name: name} = QBItemOptions.find((item) => item.Id === e.target.value);
    setFormData({
      ...formData,
      ItemRef: e.target.value,
      ItemRefName: name,
    });
  };


  const handleChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
  };

  return (
    <div>
      <Box>
        {!listLoading ? (
          <Box
            sx={{
              maxWidth: "800px",
              mx: "auto",
              pt: "25px",
            }}
          >
            <Grid
              container
              justifyContent={"center"}
            >
              <Grid item xs={12} md={8} lg={8}>
                <form onSubmit={handleSubmit}>
                  <Box>
                    <h2>Quickbooks Settings</h2>
                  </Box>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    padding={4}
                    columns={1}
                  >
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="DescriptionOnly"
                        label="Description item on all invoices"
                        multiline
                        name="DescriptionOnly"
                        value={formData.DescriptionOnly}
                        placeholder="Type Something"
                        onChange={(e) => handleChange(e.target.value, "DescriptionOnly")}
                        error={Boolean(errors.DescriptionOnly)}
                        helperText={errors.DescriptionOnly || "Leave blank if you do not want to add description to all invoices"}
                        fullWidth
                      />

                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="simple-select-label" >Select QB Item</InputLabel>
                        <Select
                          labelId="qb-item-simple-select-label"
                          id="qb-item-simple-select"
                          label="Select QB Item"
                          name="Select QB Item"
                          onChange={onQBItemSelect}
                          value={QBITemValue}
                        >
                          <MenuItem selected disabled key={"selected"}>
                            Select QB Item
                          </MenuItem>
                          {QBItemOptions?.map((item) => (
                            // console.log("item", item),
                            <MenuItem key={item.Id} value={item.Id}>
                              {item?.name}{item?.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                      <Grid item xs={12} md={6}>

                      <FormControl fullWidth>
                        <InputLabel id="simple-select-label" >Select Tax Code</InputLabel>
                        <Select
                          labelId="tax-code-simple-select"
                          id="tax-code-simple-select"
                          label="Select Tax Code"
                          name="Select Tax Code"
                          onChange={onTaxCodeSelect}
                          value={taxCodeValue}
                        >
                          <MenuItem selected disabled key={"selected"}>
                            Select Tax Code
                          </MenuItem>
                          {taxCodeOptions?.map((item) => (
                            <MenuItem key={item.Id} value={item.Id}>
                              {item?.name}{item?.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={saving}
                    >
                      Save
                      {saving && <CircularProgress color="primary" size={20} />}
                    </Button>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            padding={4}
            justifyContent="center"
          >
            <CircularProgress color="primary" size={50} />
          </Grid>
        )}
      </Box>
    </div>
  );
}
