import React, {useCallback, useEffect, useState} from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  serverTimestamp,
  Timestamp,
  increment, orderBy, limit,
} from "firebase/firestore";
import { db } from "../firebase-config";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  FormHelperText, Paper,
} from "@mui/material";
import { useSnackbar } from "notistack";
import WeekSelector from "./WeekSelector";
import {useNavigate} from "react-router-dom";

export default function AddClientToOrder({ clientID, claims }) {
  const { enqueueSnackbar } = useSnackbar();
  const [weekDate, setWeekDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [group, setGroup] = useState("1");
  const [startWeekDay, setStartWeekDay] = useState(1);
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate()

  useEffect( () => {
    const getStartDayOfWeekDoc = async () => {
      const docRef = doc(db, `/accounts/${claims.accountID}/settings/startDayOfWeek`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setStartWeekDay([
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ].indexOf(docSnap.data().startDayOfWeek));
    }
  }
  if (claims?.accountID) {
    void getStartDayOfWeekDoc();
  }
  return () => {
    setGroup("1");
    setWeekDate(null);
    setStartWeekDay(1);
  };
  }, [claims.accountID]);

  const onGroupAssign = (e) => {
    setGroup(e.target.value);
  };
  const saveClick = async () => {
    try {
      if (!weekDate) {
        setErrors({ date: "Select Week for order" });
        return;
      }
      if (!group) {
        setErrors({ group: "Select Group" });
        return;
      }
      setErrors({});
      setSaving(true);
      const q = query(
        collection(db, `/accounts/${claims.accountID}/orderList`),
        where("weekStartDate", "==", Timestamp.fromDate(weekDate))
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.size) {
        enqueueSnackbar("No orders for selected Date", {
          variant: "error",
        });
        setSaving(false);
        return;
      }
      if (querySnapshot?.docs[0]?.data()) {
        // console.log(querySnapshot.docs[0].id)
        // console.log(Number(group))
        const getPlacementsQuery = query(
          collection(db, `/accounts/${claims.accountID}/individualCuts`),
          where("orderListID", "==", querySnapshot.docs[0].id),
          where("group", "==", Number(group)),
          orderBy("placement", "desc"),
          limit(1)
        );
        const placementsQuerySnapshot = await getDocs(getPlacementsQuery);
        let placement = 1
        if (!placementsQuerySnapshot.empty){
          placement = placementsQuerySnapshot.docs[0].data().placement + 1
        }
        const cutOrderRef = collection(db, `/accounts/${claims.accountID}/individualCuts`);

        const clientRef = doc(db, `/accounts/${claims.accountID}/clients/${clientID}/`)
        const docSnap = await getDoc(clientRef);
        let price = 0
        if (docSnap.exists()) {
          price = docSnap.data().price
        }
        let fields = {
          cut: false,
          employees: [],
          cutDate: null,
          clientID: clientID,
          weekStartDate: querySnapshot.docs[0].data().weekStartDate,
          created: serverTimestamp(),
          group: Number(group),
          orderListID: querySnapshot.docs[0].id,
          placement: placement,
          price: price
        };
        await addDoc(cutOrderRef, fields);
        const orderDocRef = doc(db, `/accounts/${claims.accountID}/orderList`, querySnapshot.docs[0].id);
        await updateDoc(orderDocRef, {
          totalOrder: increment(1),
        });
      }
      setSaving(false);
      enqueueSnackbar("Added to order", { variant: "success" });
      navigate(`/edit-order/${querySnapshot.docs[0].id}`)
    } catch (error) {
      console.log(error)
      enqueueSnackbar("Error while adding client to order", {
        variant: "error",
      });
      setSaving(false);
    }
  };

  const onWeekSelection = useCallback((dateStartOfWeek) => {
    // passed up from WeekSelector
    setWeekDate(dateStartOfWeek);
  }, [])


  return (
    <Paper
      elevation={3}
      variant={'elevation'}
      sx={{
        // maxWidth: "315px",
        mx: "auto",
        mt: "25px",
        p: 3,
        pb: 5,
      }}
    >
      <Box sx={{
        mb: 6.6,
      }}>
        <h2>Add To Order</h2>
      </Box>

      <Box  paddingBottom={3}>
        <WeekSelector startWeekDay={startWeekDay} onChange={onWeekSelection}/>
      </Box>
      <Box sx={{
        maxWidth: "246px",
        mx: "auto",
      }}>
        <FormControl fullWidth>
          <InputLabel id="simple-select-label">Group</InputLabel>
          <Select
            labelId="group-simple-select-label"
            id="group-simple-select"
            label="Group"
            name="Group"
            value={group}
            onChange={onGroupAssign}
          >
            <MenuItem selected disabled>
              Select Any group
            </MenuItem>
            {Array.from({ length: 11 }, (_, i) => i + 1).map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {errors.group && (
            <FormHelperText sx={{ color: "red" }}>
              {errors.group}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          color="primary"
          variant="contained"
          onClick={saveClick}
          disabled={saving}
          fullWidth
          size={'large'}
          sx={{
            mt: 3,
          }}
        >
          Add to Existing Order
          {saving && <CircularProgress color="primary" size={20} />}
        </Button>
      </Box>
    </Paper>
  );
}

