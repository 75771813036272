import React, {useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDocs,
  query,
  orderBy,
  where,
  deleteDoc,
} from "firebase/firestore";
import {db} from "../firebase-config";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from "@mui/icons-material/Add";
import AltRouteIcon from '@mui/icons-material/AltRoute';
import {
  Box,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, LinearProgress,
} from "@mui/material";

import {
  DataGridPro,
  useGridApiRef,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";

export default function WeeklyOrders({claims}) {
  const [tableData, setTableData] = useState([]);
  const apiRef = useGridApiRef();
  let navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [listLoading, setListLoading] = useState(true);
  const [deleteExecuting, setDeleteExecuting] = useState(false)

  const loadDataGrid = useCallback (async () => {
    setListLoading(true);
    console.log('loadingData Grid')
    const q = query(
      collection(db, `accounts/${claims.accountID}/orderList`),
      orderBy("weekStartDate", "desc")
    );
    const querySnapshot = await getDocs(q);
    let records = [];
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };

    querySnapshot.forEach((item) => {
      const data = {
        id: item.id,
        weekStartDate: new Date(
          item.data().weekStartDate.seconds * 1000)
          .toLocaleDateString("en-US", options),
        created: new Date(
          item.data().created.seconds * 1000)
          .toLocaleDateString(
          "en-US", options
        ),
        totalOrder: item.data().totalOrder,
      };
      records.push(data);
    });
    setTableData([...records]);
    setListLoading(false);
  }, [claims.accountID]);

  useEffect(() => {
    if (claims?.accountID) {
      void loadDataGrid();
    } else {
      setListLoading(false);
    }
    // Cleanup:
    return () => {
      setListLoading(true);
    };
  }, [claims.accountID, navigate, loadDataGrid,]);


  const handleConfirmDelete = async () => {
    setDeleteExecuting(true)
    try {
      const ordersDocRef = query(
        collection(db, `/accounts/${claims.accountID}/individualCuts`),
        where("cut", "==", true),
        where("orderListID", "==", rowToDelete)
      );
      const getCutRecord = await getDocs(ordersDocRef);
      console.log("getCutRecord", getCutRecord);
      if (getCutRecord.size) {
        setDialogOpen(null);
        enqueueSnackbar(
          `Some of the cuts have already been completed. So you cannot delete this assignment.`,
          { variant: "error" }
        );
        setDeleteExecuting(false)
        return;
      }
      const individualCutsToDeleteRef = query(
        collection(db, `/accounts/${claims.accountID}/individualCuts`),
        where("orderListID", "==", rowToDelete)
      );
      const individualCutsToDelete = await getDocs(individualCutsToDeleteRef);

      const promises = []

      individualCutsToDelete.forEach((data) => {
        const cutOrderRef = doc(db, `/accounts/${claims.accountID}/individualCuts/${data.id}`);
        promises.push(deleteDoc(cutOrderRef))
      });

      await Promise.all(promises)
      const orderRef = doc(db, `/accounts/${claims.accountID}/orderList/${rowToDelete}`)
      await deleteDoc(orderRef)

      enqueueSnackbar("Deleted", { variant: "success" });
      setDialogOpen(null);
      await loadDataGrid();
    } catch (error) {
      console.log("err", error);
      setDialogOpen(null);
      enqueueSnackbar("Error encountered while deleting", { variant: "error" });
    }
    setDeleteExecuting(false)
  };

  const handleDeleteIconClick = (id) => (event) => {
    event.stopPropagation();
    setRowToDelete(id);
    setDialogOpen(id);
  };

  const columns = [
    { field: "weekStartDate", headerName: "Week Start Date", width: 200, editable: false,},
    { field: "totalOrder", headerName: "Total Items", width: 150, editable: false,},
    { field: "actions", type: "actions", headerName: "Actions", width: 125, getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<AltRouteIcon />}
            label="Optimize Order"
            onClick={() => navigate(`/optimize-order/${params.id}`)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<ContentCopyIcon />}
            label="Duplicate Order"
            onClick={() => navigate(`/duplicate-order/${params.id}`)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteIconClick(params.id)}
            color="inherit"
          />,
        ];
      },},
  ];

  return (
    <Box>
      <Box sx={{
        display: "flex",
        justifyContent: 'left',
        columnGap: 2,
        p:3,
        pb:0,
      }}>
        <Button
          color="primary"
          variant={"contained"}
          size={"large"}
          startIcon={<AddIcon />}
          onClick={() => navigate(`/create-weekly-order`)}
        >
          Create New Weekly Order
        </Button>
      </Box>

      <Box
      sx={{
        p: 3
      }}
    >
      <Box
        sx={{
          height: 1200,
          maxWidth: "100%",
          mx: "auto",
        }}
      >
        <DataGridPro
          loading={listLoading}
          apiRef={apiRef}
          editMode="row"
          onRowClick={(params) => {
            navigate(`/edit-order/${params.id}`);
          }}
          rows={tableData}
          columns={columns}
          components={{
            LoadingOverlay: LinearProgress,
          }}
        />
       </Box>
      <Dialog
        open={Boolean(dialogOpen)}
        onClose={() => setDialogOpen(null)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setDialogOpen(null);
              setRowToDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete}>
            Delete
            {deleteExecuting && <CircularProgress color="primary" size={20} sx={{ml: 1}}/>}
            </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </Box>
  );
}
