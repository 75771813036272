import React from "react";
import Weekday from "./components/Weekday";


import { Box } from "@mui/material";
import Quickbooks from "./components/Quickbooks";
import BusinessAddress from "./components/BusinessAddress";
// import ExternalAPIKeys from "./components/ExternalAPIKeys";

export default function Settings({claims}) {
  return (
    <Box
      sx={{
        p: 3,
      }}
    >
      <Weekday claims={claims} disabled={true}/>
      <Box sx={{mt: 5}}>
        <BusinessAddress claims={claims} />
      </Box>
      <Box sx={{mt: 5}}>
        {/*<ExternalAPIKeys claims={claims} />*/}
        <Quickbooks claims={claims} />
      </Box>
    </Box>
  );
}
