import React, {useEffect, useMemo, useState} from "react";
import {
  Grid,
  CircularProgress,
  Button,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormHelperText, FormControlLabel, Checkbox, Paper,
} from "@mui/material";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import Joi from "joi";
import {db, functions} from "../firebase-config";
import { useSnackbar } from "notistack";
import { useParams, useNavigate } from "react-router-dom";
import CutByEmployee from "../components/CutByEmployee";
import {httpsCallable} from "firebase/functions";

export default function EmployeeCreateEdit({claims}) {
  const { id } = useParams();
  const initialState = useMemo(()=> { return {
    firstName: "",
    lastName: "",
    email: "",
    group: "",
    phone: "",
    password: "",
    active: true,
  }}, [])
  const [formData, setFormData] = useState({ ...initialState });
  const [errors, setErrors] = useState({});
  const [listLoading, setListLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const newEmployeeSchema = Joi.object({
    firstName: Joi.string().min(1).max(20).required(),
    lastName: Joi.string().required(),
    email: Joi.string().email({ tlds: { allow: false } }),
    group: Joi.number().required(),
    phone: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .required(),
    password: Joi.string().min(8).required(),
    active: Joi.boolean(),
  });
  const updatedEmployeeSchema = Joi.object({
    firstName: Joi.string().min(1).max(20).required(),
    lastName: Joi.string().required(),
    email: Joi.string().email({ tlds: { allow: false } }),
    group: Joi.number().required(),
    phone: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .required(),
    password: Joi.string().equal("").optional(),
    active: Joi.boolean(),
  });

  useEffect(() => {
    const getClientDoc = async () => {
      setFormData({ ...initialState });
      setListLoading(true);
      const employeeRef = doc(db, `/accounts/${claims.accountID}/employees/${id}`);
      const employeeDoc = await getDoc(employeeRef);
      if (employeeDoc.exists()) {
        setFormData({
          password: "",
          firstName: employeeDoc.data().firstName,
          lastName: employeeDoc.data().lastName,
          email: employeeDoc.data().email,
          phone: employeeDoc.data().phone.substring(2),
          group: employeeDoc.data().group || "",
          active: employeeDoc.data().active,
        });
      } else {
        setFormData({ ...initialState });
        navigate("/employee");
      }
      setListLoading(false);
    };
    if (id && claims?.accountID) {
      void getClientDoc()
    } else {
      setFormData({ ...initialState });
      setListLoading(false);
    }
  }, [id, claims.accountID, navigate, initialState]);

  const checkUniqueEmployeeEmail = async (email) => {
    const q = query(
      collection(db, `/accounts/${claims.accountID}/employees`),
      where("email", "==", email)
    );
    const querySnapshot = await getDocs(q);
    let tempRows = [];
    querySnapshot.forEach((doc) => {
      tempRows.push(doc.id);
    });
    return tempRows;
  };


  const createNewEmployee = async () => {
    try {
      const employeesWithEmail = await checkUniqueEmployeeEmail(formData.email)
      if (employeesWithEmail.length) {
        setErrors({
          email:
            "Email is already in use for another employee. Each employee requires a unique email.",
        });
        console.log("Employee with email already exists.");
        setSaving(false);
        return;
      }

      const createNewEmployeeFn = httpsCallable(functions, 'createNewEmployee');
      try {
        const result = await createNewEmployeeFn({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: `+1${formData.phone}`,
          group: formData.group,
          password: formData.password,
          active: true,
        })
        enqueueSnackbar("Employee created successfully.", { variant: "success" });
        setSaving(false);
        navigate(`/employee/${result.data.uid}`);

      } catch (error) {
        console.warn(error);
        if (error.message === "Error Code Unique: auth/email-already-exists") {
          setErrors({
            email: "There is an existing account for that email address.",
          });
          enqueueSnackbar("There is an existing account for that email address.", {
            variant: "error",
          });
        } else if (error.message === "Error Code Unique: auth/phone-number-already-exists") {
          setErrors({
            phone: "There is an existing account for that phone number.",
          });
          enqueueSnackbar("There is an existing account for that phone number.", {
            variant: "error",
          });
        }
        setSaving(false);
        return
      }
      setSaving(false)
    } catch (err) {
      console.log("err", err);
      setSaving(false);
      enqueueSnackbar("Error in Employee Creation", { variant: "error" });
    }
  };

  const updateEmployee = async () => {
    const employeesWithEmail = await checkUniqueEmployeeEmail(formData.email)
    const isEmployeeEmailError = employeesWithEmail.some(
      (foundID) => foundID !== id
    );
    if (isEmployeeEmailError) {
      setErrors({
        phone:
          "Email is already in use for another Employee. Each Employee requires a unique email.",
      });
      console.log("Employee with email already exists.");
      setSaving(false);
      return;
    }
    const docRef = doc(db, `/accounts/${claims.accountID}/employees/${id}`);
    const fieldsToUpdate = {
      ...formData,
      phone: `+1${formData.phone}`,
    }
    delete fieldsToUpdate.password

    await updateDoc(docRef, fieldsToUpdate)
      .then(() => {
        setSaving(false);
        enqueueSnackbar("Employee updated", { variant: "success" });
        navigate(`/employee/${id}`);
      })
      .catch((err) => {
        console.log("err", err);
        setSaving(false);
        enqueueSnackbar("Error in employee update", { variant: "error" });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('here')
    let result
    if (id) {
      result = updatedEmployeeSchema.validate(formData);
    } else {
      result = newEmployeeSchema.validate(formData);
    }
    // console.log("formData", formData, result);
    const { error } = result;
    setSaving(true);
    if (!error) {
      setErrors({});
      if (!id) {
        await createNewEmployee();
      } else {
        await updateEmployee();
      }
    } else {
      setSaving(false);
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        errorData[name] = item.message;
      }
      setErrors(errorData);
    }
  };

  const handleChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
  };

  return (
    <div>
      <Box>
        {!listLoading ? (
          <div>
            <Paper
              sx={{
                maxWidth: "600px",
                mx: "auto",
                pt: "25px",
                pb: "50px",
                mt: "25px",
              }}
            >
              <Grid
                container
                justifyContent={"center"}
              >
                <Grid item xs={12} md={8} lg={8}>
                  <Box>
                    <h2>Employee details</h2>
                  </Box>
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      py={4}
                      columns={1}
                    >
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="firstName-field"
                          label="First Name"
                          required
                          name="firstName"
                          value={formData.firstName}
                          placeholder="First Name"
                          onChange={(e) =>
                            handleChange(e.target.value, "firstName")
                          }
                          error={Boolean(errors.firstName)}
                          helperText={errors.firstName || ""}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="lastName-field"
                          label="Last Name"
                          required
                          name="lastName"
                          value={formData.lastName}
                          placeholder="Last Name"
                          onChange={(e) =>
                            handleChange(e.target.value, "lastName")
                          }
                          error={Boolean(errors.lastName)}
                          helperText={errors.lastName || ""}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="email-field"
                          label="Email"
                          required
                          name="email"
                          disabled={Boolean(id)}
                          value={formData.email}
                          placeholder="Email"
                          onChange={(e) =>
                            handleChange(e.target.value, "email")
                          }
                          error={Boolean(errors.email)}
                          helperText={errors.email || ""}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="phone-field"
                          label="Phone"
                          required
                          name="phone"
                          value={formData.phone}
                          placeholder="Phone"
                          onChange={(e) =>
                            handleChange(e.target.value, "phone")
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +1
                              </InputAdornment>
                            ),
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          error={Boolean(errors.phone)}
                          helperText={errors.phone || ""}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={3} lg={3}>
                        <FormControl fullWidth error={Boolean(errors.group)}>
                          <InputLabel id="simple-select-label">
                            Group
                          </InputLabel>
                          <Select
                            labelId="simple-select-label"
                            id="simple-select"
                            label="Group"
                            name="Group"
                            value={formData.group}
                            onChange={(e) =>
                              handleChange(e.target.value, "group")
                            }
                          >
                            <MenuItem selected disabled>
                              Select Any group
                            </MenuItem>
                            {Array.from({ length: 11 }, (_, i) => i + 1).map(
                              (item, index) => (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              )
                            )}
                          </Select>
                          {errors.group && (
                            <FormHelperText>{errors.group}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {!id && (
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="password-field"
                            label="Password"
                            name="password"
                            type="password"
                            value={formData.password}
                            placeholder="Password"
                            onChange={(e) =>
                              handleChange(e.target.value, "password")
                            }
                            error={Boolean(errors.password)}
                            helperText={errors.password || ""}
                            fullWidth
                          />
                        </Grid>
                      )}
                      {id && (
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formData.active}
                                onChange={(e) =>
                                  handleChange(e.target.checked, "active")
                                }
                              />
                            }
                            label="Active"
                          />
                          {errors.active && (
                            <div className="alert alert-danger">
                              {errors.active}
                            </div>
                          )}
                        </Grid>
                      )}
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={saving}
                      >
                        {id ? "Save" : "Create"}
                        {saving && (
                          <CircularProgress color="primary" size={20} />
                        )}
                      </Button>
                    </Box>
                  </form>
                </Grid>
              </Grid>
            </Paper>
            {id && <CutByEmployee
              employeeID={id}
              claims={claims}
              />}
          </div>
        ) : (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            padding={4}
            justifyContent="center"
          >
            <CircularProgress color="primary" size={50} />
          </Grid>
        )}
      </Box>
    </div>
  );
}
