import Typography from "@mui/material/Typography";
import {Card, CardContent} from "@mui/material";
import {useNavigate} from "react-router-dom";


export default function DashboardNotesCard({noteData}) {
    const navigate = useNavigate();

    return (
        <Card sx={{  mb: 1, mx: 1 }} elevation={4}>
            <CardContent onClick={()=> {navigate(`/client/${noteData.clientID}`)}}>
                <Typography variant="h7"  textAlign={"center"}>
                    {noteData.client.address}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{px:.5}}>
                    {noteData.note}
                </Typography >
                <Typography variant="body2" sx={{px:.5, mt:2}}>
                    {new Date(noteData.created.seconds * 1000).toLocaleString(
                        "en-US"
                    )}
                    <br/>
                    {noteData.userEmail}
                </Typography>
            </CardContent>
        </Card>
    );
}