import './App.css';
import {useEffect, useState} from "react";
import { onAuthStateChanged, signOut } from "firebase/auth"
import { auth } from "./firebase-config"
import {Navigate, Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {AppBar, Box, Button, CircularProgress, Toolbar} from "@mui/material";
import  Login from './pages/Login'
import Register from './pages/Register'
import { LicenseInfo } from '@mui/x-license-pro';
import ClientCreateEdit from "./pages/ClientCreateEdit";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import * as React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { ListItemButton } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AttributionOutlinedIcon from '@mui/icons-material/AttributionOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import ClientsList from "./pages/ClientsList";
import Settings from "./pages/Settings";
import CreateWeeklyOrder from "./pages/CreateWeeklyOrder";
import EditWeeklyOrder from "./pages/EditWeeklyOrder";
import WeeklyOrders from "./pages/WeeklyOrders";
import DuplicateWeeklyOrder from "./pages/DuplicateWeeklyOrder";
import CutsFromDateRange from "./pages/CutsFromDateRange";
import EmployeeCreateEdit from "./pages/EmployeeCreateEdit";
import Employees from "./pages/Employees";
import MyOrders from "./pages/Employee/MyOrders";
import OrderDetails from "./pages/Employee/OrderDetails";
import Invoices from "./pages/Invoices";
import {
  AccountBalanceOutlined,
  DescriptionOutlined,
  GroupsOutlined,
  WorkHistoryOutlined
} from "@mui/icons-material";
import {OptimizeWeeklyOrder} from "./pages/OptimizeWeeklyOrder";
import Dashboard from "./pages/Dashboard";

LicenseInfo.setLicenseKey(
  '15b006342a017b480b9170f5fa9f6cd1T1JERVI6MzQxNjksRVhQSVJZPTE2NzA3Njg4NDgwMDAsS0VZVkVSU0lPTj0x',
);

function App() {
  const [user, setUser] = useState({})
  const [claims, setClaims] = useState({})
  const [isCustomClaimRequested, setIsCustomClaimRequested] = useState(false)

  useEffect(() => {
    if (isCustomClaimRequested) {
      auth.currentUser.getIdTokenResult()
        .then((tokenResult) => {
          if (tokenResult.claims) {
            setClaims(tokenResult.claims)
            // console.log("tokenResult.claims", tokenResult.claims)
          }
        })
    }
  }, [isCustomClaimRequested])

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser)
    if(currentUser) {
      setIsCustomClaimRequested(true)
      localStorage.setItem("signedIn", "true")
    } else {
      localStorage.removeItem("signedIn")
      setIsCustomClaimRequested(false)
    }
  })

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route element={<Layout claims={claims} user={user}/>}>
          <Route path="/" element={<PublicPage claims={claims}/>} />
          <Route
            path="/home"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin', "employee"]}>
                <HomePage user={user} claims={claims}/>
              </RequireAuth>
            }
          />
          <Route
            path="/client"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <ClientCreateEdit user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/client/:id"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <ClientCreateEdit user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/clients"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <ClientsList user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/settings"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <Settings user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/order-list"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <WeeklyOrders user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/create-weekly-order"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <CreateWeeklyOrder user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/edit-order/:id"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <EditWeeklyOrder user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/optimize-order/:id"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <OptimizeWeeklyOrder user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/duplicate-order/:id"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <DuplicateWeeklyOrder user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/reports"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <CutsFromDateRange user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/employee"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <EmployeeCreateEdit user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/employee/:id"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <EmployeeCreateEdit user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/employees"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <Employees user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/orders"
            element={
              <RequireAuth claims={claims} allowedPermissions={['employee']}>
                <MyOrders user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/order/:id"
            element={
              <RequireAuth claims={claims} allowedPermissions={['employee']}>
                <OrderDetails user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/invoices"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <Invoices user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth claims={claims} allowedPermissions={['admin']}>
                <Dashboard user={user} claims={claims} />
              </RequireAuth>
            }
          />
          <Route path="*" element={<PublicPage/>} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout({window, claims, user}) {
  const drawerWidth = 200;
  const navigate = useNavigate()
  const [mobileOpen, setMobileOpen] = useState(false);

  const logout = async () => {
    await signOut(auth)
  }

  const handleDrawerItemClick = (path) => {
    navigate(path)
    setMobileOpen(false)
  }

  const drawer = (
    <Box>
      <Toolbar>
        Grass Pro App
      </Toolbar>
      <Divider />
      { claims.permissionLevel === "admin" &&
        <List>
            <ListItemButton key={"dashboard"} onClick={()=> handleDrawerItemClick('/dashboard')}>
                <ListItemIcon>
                    <DashboardCustomizeOutlinedIcon />
                </ListItemIcon>
                <ListItemText>
                    Dashboard
                </ListItemText>
            </ListItemButton>
            <ListItemButton key={"clients"} onClick={() => handleDrawerItemClick('/clients')}>
            <ListItemIcon>
              <AttributionOutlinedIcon/>
            </ListItemIcon>
            <ListItemText>
              Clients
            </ListItemText>
          </ListItemButton>
          <ListItemButton key={"order-list"} onClick={()=> handleDrawerItemClick('/order-list')}>
            <ListItemIcon>
              <WorkHistoryOutlined />
            </ListItemIcon>
            <ListItemText>
              Orders
            </ListItemText>
          </ListItemButton>
          <ListItemButton key={"invoices"} onClick={()=> handleDrawerItemClick('/invoices')}>
            <ListItemIcon>
              <AccountBalanceOutlined />
            </ListItemIcon>
            <ListItemText>
              Invoices
            </ListItemText>
          </ListItemButton>
          <ListItemButton key={"reports"} onClick={()=> handleDrawerItemClick('/reports')}>
            <ListItemIcon>
              <DescriptionOutlined />
            </ListItemIcon>
            <ListItemText>
              Reports
            </ListItemText>
          </ListItemButton>
          <ListItemButton key={"employees"} onClick={()=> handleDrawerItemClick('/employees')}>
            <ListItemIcon>
              <GroupsOutlined />
            </ListItemIcon>
            <ListItemText>
              Employees
            </ListItemText>
          </ListItemButton>
          <ListItemButton key={"settings"} onClick={()=> handleDrawerItemClick('/settings')}>
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              Settings
            </ListItemText>
          </ListItemButton>
        </List>
      }
      {claims.permissionLevel === "employee" &&
        <List>
          <ListItemButton key={"orders"} onClick={()=> handleDrawerItemClick('/orders')}>
            <ListItemIcon>
              <AccountCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              My Orders
            </ListItemText>
          </ListItemButton>
        </List>

      }
        </Box>
  )

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Grass Pro
          </Typography>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          minHeight: "100vh",
          flexDirection: "column",
          display: "flex",
          justifyContent: "space-between",
          flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Box>
          <Toolbar/>
          <Outlet />
        </Box>
        <Box>
          {user?.email ?
            <Box textAlign="center" px={3}>
              <h4>User Logged in: {user.email} </h4><Button onClick={logout}>Sign Out</Button>
            </Box>
            : null}
        </Box>
      </Box>
    </Box>
  );
}

function RequireAuth({ children, claims, allowedPermissions }) {
  const [render, setRender] = useState(0)
  let location = useLocation()
  const signedIn = localStorage.getItem("signedIn")
  if (!auth.currentUser && signedIn) {
    if (render === 0) {
      setRender(1) // rerender to update localstorage
    }
    return <Box textAlign="center"><CircularProgress /></Box>
  } else if (auth.currentUser && signedIn) {
    if (allowedPermissions.includes(claims.permissionLevel)){
      return children
    } else if (claims.permissionLevel === undefined) {
      return null
    } else {
      return <Navigate to="/"/>
    }
  } else if (!auth.currentUser && !signedIn) {
    return  <Navigate to="/login" state={{ from: location }} />
  }
  return  <Navigate to="/login" state={{ from: location }} />
}

function PublicPage() {
  const [render, setRender] = useState(0)
  const signedIn = localStorage.getItem("signedIn")
  if (!auth.currentUser && signedIn) {
    if (render === 0) {
      setRender(1) // rerender to update localstorage
    }
    return <Box textAlign="center"><CircularProgress /></Box>
  } else if (auth.currentUser && signedIn) {
    return <Navigate to="/home" />
  } else if (!auth.currentUser && !signedIn) {
    return  <Navigate to="/login" />
  }
  return  <Navigate to="/login" />
}


export function HomePage({claims}){
  const navigate = useNavigate()
  useEffect(() =>{
    if(claims?.permissionLevel === "admin"){
      navigate("/dashboard")
    } else if (claims?.permissionLevel === "employee"){
      navigate("/orders")
    }
  }, [claims, navigate])
  return (
    <Box>
    </Box>
  )
}


export default App;
