import React, {useCallback, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGridPro,
  useGridApiRef,
  GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";

import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  deleteDoc, where, limit
} from "firebase/firestore";
import { db } from "../firebase-config";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, LinearProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";

export default function Employees({claims}) {
  const [tableData, setTableData] = useState([]);
  let navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [listLoading, setListLoading] = useState(false);
  const apiRef = useGridApiRef();

  const isMounted = useRef(true)

  const loadDataGrid = useCallback(async () => {
    setListLoading(true);
    const q = query(collection(db, `/accounts/${claims.accountID}/employees`), orderBy("created", "desc"));
    const querySnapshot = await getDocs(q);
    let tempRows = [];
    await querySnapshot.forEach((doc) => {
      const data = {
        id: doc.id,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        email: doc.data().email,
        phone: doc.data().phone,
        group: doc.data().group,
        active: doc.data().active,
        created: new Date(doc.data().created.seconds * 1000).toLocaleDateString(
          "en-US"
        ),
      };
      tempRows.push(data);
    });
    if (isMounted.current){
      setTableData([...tempRows]);
      setListLoading(false);
    }
  }, [claims.accountID, isMounted])

  useEffect(() => {

    if (claims?.accountID) {
      void loadDataGrid();
    }
    return ()=>{
      isMounted.current = false
      setListLoading(false)
    }
  }, [claims.accountID, navigate, loadDataGrid]);



  const handleConfirmDelete = async () => {
    const id = rowToDelete;
    const individualCutsQueryByEmployee = query(
      collection(db, `/accounts/${claims.accountID}/individualCuts`),
      limit(1),
      where("employees", "array-contains", id),
      where("cut", "==", true),
    );
    const employeeCutsResults = await getDocs(individualCutsQueryByEmployee);
    if (employeeCutsResults.size) {
      enqueueSnackbar("Employee has completed cuts. So they cannot be deleted. Please deactivate instead.", {
        variant: "error",
      });
      setDialogOpen(null);
    } else {
      const docRef = doc(db, `/accounts/${claims.accountID}/employees/${rowToDelete}`);
      await deleteDoc(docRef);
      enqueueSnackbar("Successfully Deleted", { variant: "success" });
      setDialogOpen(null);
      apiRef.current.updateRows([{ id, _action: "delete" }]);
      const allIdsNew = apiRef.current.getAllRowIds();
      const allRows = allIdsNew.map((id) => {
        return apiRef.current.getRow(id);
      });
      setTableData(allRows);
    }
  };

  const handleDeleteIconClick = (id) => (event) => {
    event.stopPropagation();
    setRowToDelete(id);
    setDialogOpen(id);
  };

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        {/*<Box sx={{ displayPrint: "none" }}>*/}
        {/*  <Button*/}
        {/*    color="primary"*/}
        {/*    startIcon={<AddIcon />}*/}
        {/*    onClick={() => navigate(`/employee`)}*/}
        {/*  >*/}
        {/*    Add Employee*/}
        {/*  </Button>*/}
        {/*  <GridToolbarExport />*/}
        {/*</Box>*/}
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "firstName", headerName: "First Name", width: 150, editable: false },
    { field: "lastName", headerName: "Last Name", width: 150, editable: false },
    { field: "phone", headerName: "Phone", width: 150, editable: false },
    { field: "email", headerName: "Email", width: 250, editable: false },
    { field: "created", headerName: "Created", width: 200, editable: false },
    { field: "active", headerName: "Active", width: 100, editable: false, type: "boolean" },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteIconClick(id)}
            color="inherit"
          />,
        ]
      }}
  ];

  const handleRowClick = async (params) => {
    navigate(`/employee/${params.id}`);
  };

  return (
<Box>
  <Box sx={{
    display: "flex",
    justifyContent: 'left',
    columnGap: 2,
    p:3,
    pb:0,
  }}>

  <Button
      color="primary"
      variant={'contained'}
      startIcon={<AddIcon />}
      onClick={() => navigate(`/employee`)}
    >
      Add Employee
    </Button>
  </Box>
  <Box
      sx={{
        p: 3
      }}
    >
      <Box
        sx={{
          height: 1200,
          maxWidth: "100%",
          mx: "auto",
        }}
      >
        <DataGridPro
          editMode="row"
          rows={tableData}
          columns={columns}
          apiRef={apiRef}
          onRowClick={handleRowClick}
          components={{
            Toolbar: EditToolbar,
            LoadingOverlay: LinearProgress,
          }}
          loading={listLoading}
        />
      </Box>
      <Dialog
        open={Boolean(dialogOpen)}
        onClose={() => setDialogOpen(null)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this employee?
            Delete operation will fail if employee has completed cuts. Change their Active status in their profile instead, which will disable their access to this application.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setDialogOpen(null);
              setRowToDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
</Box>
  );
}
