import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  FormControl,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import Joi from "joi";

import { doc, getDoc, setDoc} from "firebase/firestore";
import { db } from "../../../firebase-config";
import { useSnackbar } from "notistack";

const initialState = {
  startDayOfWeek: "",
}
export default function WeekdaySettings({claims, disabled}) {
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const weekdaySchema = Joi.object({
    startDayOfWeek: Joi.string().required(),
  });

  useEffect(() => {
    let isMounted = true
    const getStartDayOfWeekDoc = async (accountID) => {
      setLoading(true);
      const docRef = doc(db, `accounts/${accountID}/settings/startDayOfWeek`);
      const docSnap = await getDoc(docRef);
      if (isMounted && docSnap.exists()) {
        setFormData({
          startDayOfWeek: docSnap.data().startDayOfWeek,
        });
      } else if (isMounted) {
        setFormData({
          startDayOfWeek: "Monday",
        });
        await setDoc(docRef, {
          startDayOfWeek: "Monday",
        }, { merge: true });
      }
      setLoading(false);
    };
    if (claims?.accountID) {
      void getStartDayOfWeekDoc(claims.accountID);
    }
    return ()=> {
      setLoading(true)
      isMounted = false
      setFormData(initialState)
      setErrors({})
    }
  }, [claims.accountID]);

  const handleChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
  };

  const updateWeekStartDays = async () => {
    const docRef = doc(db, `/accounts/${claims.accountID}/settings/startDayOfWeek`);
    await setDoc(docRef, {
      ...formData,
    })
      .then(() => {
        setSaving(false);
        enqueueSnackbar("Save Successful", { variant: "success" });
      })
      .catch((err) => {
        console.log("err", err);
        setSaving(false);
        enqueueSnackbar("Error encountered while updating", { variant: "error" });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData.startDayOfWeek)
    // return
    const result = weekdaySchema.validate(formData);
    const { error } = result;
    setSaving(true);
    if (!error) {
      setErrors({});
      await updateWeekStartDays();
    } else {
      setSaving(false);
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        errorData[name] = item.message;
      }
      setErrors(errorData);
      return errorData;
    }
  };
  return (
    <div>
      <Box>
        {!loading ? (
          <Box
            sx={{
              maxWidth: "800px",
              mx: "auto",
              pt: "25px",
            }}
          >
            <Grid container justifyContent={"center"}>
              <Grid item xs={12} md={8} lg={8}>
                <form onSubmit={handleSubmit}>
                  <Box>
                    <h2>Weekly starting Day</h2>
                  </Box>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    padding={4}
                    columns={1}
                  >
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth error={Boolean(errors.lists)}>
                        <InputLabel id="demo-simple-select-label">
                          Day to start week on
                        </InputLabel>
                        <Select
                          labelId="day-of-week-simple-select"
                          id="start-day-of-week"
                          label="Weekly starting day"
                          required
                          name="startDayOfWeek"
                          value={formData.startDayOfWeek}
                          disabled={disabled}
                          onChange={(e) =>
                            handleChange(e.target.value, "startDayOfWeek")
                          }
                        >
                          {[
                            "Sunday",
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                          ].map((item, index) => (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={disabled}
                    >
                      Save
                      {saving && <CircularProgress color="primary" size={20} />}
                    </Button>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            padding={4}
            justifyContent="center"
          >
            <CircularProgress color="primary" size={50} />
          </Grid>
        )}
      </Box>
    </div>
  );
}
