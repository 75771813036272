import React, { useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import {auth} from "../firebase-config";
import {CircularProgress, Button, TextField, Box, Container} from "@mui/material";
import Joi from "joi";

const loginSchema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }),
  password: Joi.string().required(),
});

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  let location = useLocation();
  let from = location.state?.from?.pathname || "/";


  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = loginSchema.validate(formData);
    const { error } = result;
    setSaving(true);
    if (!error) {
      setErrors({});
      handleLogin();
    } else {
      setSaving(false);
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        switch(item.type) {
          case "string.email":
            errorData[name] = "Invalid email";
            break;
          case "string.empty":
            errorData[name] = "This field is required";
            break;
          default:
            errorData[name] = item.message;
        }
      }
      setErrors(errorData);
    }
  };

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, formData.email, formData.password)
      .then(() => {
        navigate(from, { replace: true })
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/user-not-found":
            setErrors({ ...errors, email: "Email Not Found!" });
            break;
          case "auth/wrong-password":
            setErrors({ ...errors, password: "Incorrect Password!" });
            break;
          default:
            return;
        }
        setSaving(false);
      });
  };

  const handleChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
    setErrors({ ...errors, [field]: null });
  };

  return (
    <Container maxWidth="xs">
      <form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <h1>Grass Pro Login</h1>
        </Box>
        <Box sx={{ mt: 1 }}>
        <TextField
                required
                id="email"
                label="Email"
                placeholder="Email"
                type="email"
                name="email"
                autoComplete={"username"}
                value={formData.email}
                onChange={(e) => handleChange(e.target.value, "email")}
                error={Boolean(errors.email)}
                helperText={errors.email || ""}
                fullWidth
              />
              <TextField
                required
                id="password"
                label="Password"
                placeholder="Password"
                type="password"
                name="password"
                autoComplete={"current-password"}
                value={formData.password}
                onChange={(e) =>
                  handleChange(e.target.value, "password")
                }
                error={Boolean(errors.password)}
                helperText={errors.password || ""}
                fullWidth
                sx={{
                  marginTop: 3,
                }}
              />
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              size={'large'}
              disabled={saving}
              sx={{
                marginTop: 3,
                py:1.5,
              }}
            >
              Login
              {saving && <CircularProgress color="primary" size={20} />}
            </Button>
          </Box>
      </Box>
        </form>
    </Container>
  );
};

export default Login;