import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, CircularProgress, Paper, Stack, Tooltip, Typography} from "@mui/material";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    where,
    collectionGroup,
    limit,
    orderBy,
    documentId
} from "firebase/firestore";
import {db} from "../firebase-config";
import WeekSelector from "../components/WeekSelector";
import LinearProgressLabelAndBuffer from "../components/LinearProgressLabelAndBuffer";
import DashboardNotesCard from "../components/DashboardNotesCard";
import PastDueCuts from "../components/PastDueCuts";

// Need to get all clients.
// Need to get all OrderList for current week
// Q: Should you subscribe to changes, when using this dashboard?

export default function Dashboard({claims}) {
    const [startWeekDay, setStartWeekDay] = useState(1);
    const [weekDate, setWeekDate] = useState(null);
    // const [employeesLists, setEmployeesLists] = useState(null);
    const [currentDayOfWeek,] = useState(() => new Date().getDay());
    const [recentNotesLoading, setRecentNotesLoading] = useState(false)
    // const [employeeData, setEmployeeData] = useState(null)
    const [individualCutsData, setIndividualCutsData] = useState(null)
    // const [clientsData, setClientsData] = useState(null)
    const [cutsByGroupNumber, setCutsByGroupNumber] = useState(null)
    const [targetFinishDay, setTargetFinishDay] = useState(4)
    const [daysToAdjustTargetDay, setDaysToAdjustTargetDay] = useState(0)
    const [lastThirtyNotes, setLastThirtyNotes] = useState(null)

    const {accountID} = claims

    const getRecentComments = useCallback(async () => {
        setRecentNotesLoading(true);
        const notesRef = query(
            collectionGroup(db, 'notes'),
            where("accountID", "==", accountID),
            orderBy("created", "desc"),
            limit(30)
        );
        const querySnapshot = await getDocs(notesRef);
        const clientIDs = [];
        const noteData = [];
        querySnapshot.forEach((doc) => {
            const docData = doc.data();
            clientIDs.push(docData.clientID);
            noteData.push({id: doc.id, ...docData});
        });
        const uniqClientIDs = [...new Set(clientIDs)];
        const chunkSize = 10;
        const chunkedClientIDs = [];
        for (let i = 0; i < uniqClientIDs.length; i += chunkSize) {
            const chunk = uniqClientIDs.slice(i, i + chunkSize);
            chunkedClientIDs.push(chunk);
        }
        const clientsDataForNotes = {};
        const clientQueries = chunkedClientIDs.map((chunk) => {
            const clientsRef = query(collection(db, `accounts/${accountID}/clients/`), where(documentId(), "in", chunk));
            return getDocs(clientsRef);
        });
        const querySnapshotsClients = await Promise.all(clientQueries);
        querySnapshotsClients.forEach((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                clientsDataForNotes[doc.id] = doc.data();
            });
        });
        const preparedNotes = noteData.map((note) => {
            note["client"] = clientsDataForNotes[note.clientID];
            return note;
        });
        setLastThirtyNotes(preparedNotes);
        setRecentNotesLoading(false);
    }, [accountID]);


    // const getEmployees = useCallback(async () => {
    //     const employeesRef = query(
    //         collection(db, `accounts/${accountID}/employees/`),
    //     );
    //     const employeesDocs = await getDocs(employeesRef);
    //     const data = [];
    //     const employeeListPrep = {}
    //     await employeesDocs.forEach((doc) => {
    //         data.push({...doc.data(), id: doc.id});
    //         employeeListPrep[doc.id] = doc.data().firstName + " " + doc.data().lastName;
    //     })
    //     setEmployeesLists(employeeListPrep);
    //     setEmployeeData(data)
    // }, [accountID])
    // const getClients = useCallback(async () => {
    //     const clientsRef = query(
    //         collection(db, `accounts/${accountID}/clients/`),
    //     );
    //     const clientsDoc = await getDocs(clientsRef);
    //     const data = [];
    //     await clientsDoc.forEach((doc) => {
    //         data.push({...doc.data(), id: doc.id});
    //     })
    //     setClientsData(data)
    // }, [accountID])

    const getIndividualCuts = useCallback(async () => {
        const nextDay = new Date(weekDate.getTime() + 60 * 60 * 24 * 1000);
        const prevDay = new Date(weekDate.getTime() - 60 * 60 * 24 * 1000);
        const individualCutsRef = query(
            collection(db, `/accounts/${accountID}/individualCuts`),
            where("weekStartDate", "<", nextDay),
            where("weekStartDate", ">", prevDay),
        )
        const individualCutsDocs = await getDocs(individualCutsRef);
        const data = [];
        await individualCutsDocs.forEach((doc) => {
            data.push({...doc.data(), id: doc.id});
        })
        setIndividualCutsData(data)
    }, [accountID, weekDate])

    const getStartWeekDayDoc = useCallback(async () => {
        const docRef = doc(db, `accounts/${accountID}/settings/startDayOfWeek`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setStartWeekDay([
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
            ].indexOf(docSnap.data().startDayOfWeek));
        }
    }, [accountID])


    useEffect(() => {
        if (targetFinishDay === 4) {
            setDaysToAdjustTargetDay(0)
        }
        if (targetFinishDay === 5) {
            setDaysToAdjustTargetDay(1)
        }
        if (targetFinishDay === 6) {
            setDaysToAdjustTargetDay(2)
        }
    }, [targetFinishDay])

    useEffect(() => {
        if (accountID) {
            const promises = []
            promises.push(getStartWeekDayDoc().then(r => console.log("StartWeekDay Loaded")))
            // promises.push(getEmployees().then(r => console.log("Employees Loaded")))
            // promises.push(getClients().then(r => console.log("Clients Loaded")))
            promises.push(getRecentComments().then(r => console.log("Comments Loaded")))
            Promise.all(promises).then(r => console.log("Employees, weekday, clients, comments Loaded"))
        }
        return () => {
            setStartWeekDay(1);
        };
    }, [accountID, getStartWeekDayDoc, getRecentComments])

    useEffect(() => {
        if (weekDate !== null && weekDate !== undefined && accountID) {
            getIndividualCuts().then(r => console.log("Cuts Loaded"))
        }
    }, [accountID, weekDate, getIndividualCuts])

    const onWeekSelection = useCallback((dateStartOfWeek) => {
        // passed up from WeekSelector
        setWeekDate(dateStartOfWeek);
    }, [])

    useEffect(() => {
        if (individualCutsData) {
            const byGroupNumber = individualCutsData.reduce((acc, cut) => {
                if (Object.hasOwn(acc, cut.group)) {
                    acc[cut.group].push(cut)
                } else {
                    acc[cut.group] = [cut]
                    acc.groupNumbers.push(cut.group)
                }
                return acc
            }, {groupNumbers: []})
            byGroupNumber.groupNumbers.sort((a, b) => a - b)
            byGroupNumber.cutResults = {}
            byGroupNumber.totals = {cut: 0, notCut: 0, revenue: 0, missingRevenue: 0}
            for (let i = 0; i < byGroupNumber.groupNumbers.length; i++) {
                const cutResults = byGroupNumber[byGroupNumber.groupNumbers[i]].reduce((acc, eachCut) => {
                    if (eachCut.cut) {
                        acc.cut = acc.cut + 1
                        if (eachCut.price > 0) {
                            acc.revenue = acc.revenue + eachCut.price
                        }
                        if (byGroupNumber.groupNumbers[i] < 11) {
                            byGroupNumber.totals.cut = byGroupNumber.totals.cut + 1
                            // Sometimes price is null, if not entered.
                            if (eachCut.price > 0) {
                                byGroupNumber.totals.revenue = byGroupNumber.totals.revenue + eachCut.price
                            }
                        }
                    } else {
                        acc.notCut = acc.notCut + 1
                        if (eachCut.price > 0) {
                            acc.missingRevenue = acc.missingRevenue + eachCut.price
                        }
                        if (byGroupNumber.groupNumbers[i] < 11) {
                            byGroupNumber.totals.notCut = byGroupNumber.totals.notCut + 1
                            // Sometimes price is null, if not entered.
                            if (eachCut.price > 0) {
                                byGroupNumber.totals.missingRevenue = byGroupNumber.totals.missingRevenue + eachCut.price
                            }
                        }
                    }
                    return acc
                }, {cut: 0, notCut: 0, revenue: 0, missingRevenue: 0})
                byGroupNumber.cutResults[byGroupNumber.groupNumbers[i]] = cutResults
            }
            setCutsByGroupNumber(byGroupNumber)
        }
    }, [individualCutsData])

    // const displayPercent = (percent) => `${(percent * 100).toFixed(2)}%`;
    const hoursToRemoveFromTargetPercentage = () => {
        const currentHour = new Date().getHours()
        // 2.5 hours for each hour
        if (currentHour < 8) {
            return 20
        }
        if (currentHour > 16) {
            return 0
        }
        return 20 - ((currentHour - 8) * 2.5)
    }
    const verifyPositive = (val) => {
        if (val > 0) {
            return val
        }
        return 0
    }

    const replaceWithTargetDay = () => {
        if (targetFinishDay === 4) {
            return "Friday"
        }
        if (targetFinishDay === 5) {
            return "Saturday"
        }
        if (targetFinishDay === 6) {
            return "Sunday"
        }
    }

    return (
        <Box sx={{
            pt: 3,
            pl: .5,
            pr: .5,
        }}
        >
            <Paper elevation={10} sx={{
                p: 2,
                m: 2,
                textAlign: "left"
            }}>
                <Stack direction={"row"} sx={{ml: 2}}>
                    <Box sx={{height:"75px", pt:.5}}>
                        <WeekSelector startWeekDay={startWeekDay} onChange={onWeekSelection} center={true}/>
                    </Box>
                    <Stack>
                        <Stack direction={"row"} sx={{ml: 2}}>
                            <Button onClick={() => setTargetFinishDay(4)}>Friday</Button>
                            <Button onClick={() => setTargetFinishDay(5)}>Saturday</Button>
                            <Button onClick={() => setTargetFinishDay(6)}>Sunday</Button>
                        </Stack>
                        <Stack direction={"row"} sx={{ml: 3}}>
                            Should be
                            at {verifyPositive(((currentDayOfWeek - daysToAdjustTargetDay) * 20) - hoursToRemoveFromTargetPercentage())}%
                            now to finish by {replaceWithTargetDay()}
                        </Stack>

                    </Stack>

                </Stack>
                {cutsByGroupNumber !== null && (<>
                    <Box sx={{
                        p: 1,
                        m: 1
                    }}>

                        <Stack>
                            {
                                cutsByGroupNumber.groupNumbers.map((num) => {
                                    if (num < 11) {
                                        const remainingCuts = `${cutsByGroupNumber.cutResults[num].cut} / ${cutsByGroupNumber[num].length}`
                                        const progressPercentage = (cutsByGroupNumber.cutResults[num].cut / cutsByGroupNumber[num].length) * 100
                                        let bufferPercentage = ((currentDayOfWeek - daysToAdjustTargetDay) * 20) - hoursToRemoveFromTargetPercentage()
                                        const missingPercentage = bufferPercentage - progressPercentage
                                        // The LinearProgressLabelAndBuffer doesn't display correctly if bufferPercentage is 0
                                        if (bufferPercentage === 0) {bufferPercentage = -0.001}
                                        return (
                                            <Stack direction={"row"} key={num}>
                                                <Tooltip title={remainingCuts}>
                                                    <Box sx={{width: "120px", textAlign: "left"}}>
                                                        Group {num}:
                                                    </Box>
                                                </Tooltip>
                                                <LinearProgressLabelAndBuffer
                                                    progress={progressPercentage}
                                                    buffer={bufferPercentage}/>
                                                {missingPercentage > 0 ?
                                                    (<Box sx={{width: "200px", textAlign: "left"}}>
                                                        Losing:
                                                        ${((cutsByGroupNumber.cutResults[num].revenue + cutsByGroupNumber.cutResults[num].missingRevenue) * (missingPercentage / 100)).toFixed(2)}
                                                    </Box>)
                                                    : (
                                                        <Box sx={{width: "200px", textAlign: "left"}}></Box>
                                                    )
                                                }
                                            </Stack>

                                        )
                                    }
                                    return (<Box key={Math.random()}></Box>)
                                })
                            }
                            <Stack direction={"row"} key={"totals"} sx={{mt: 2}}>
                                <Tooltip
                                    title={`${cutsByGroupNumber.totals.cut} / ${cutsByGroupNumber.totals.cut + cutsByGroupNumber.totals.notCut}`}>
                                    <Box sx={{width: "120px", textAlign: "left"}}>
                                        All Groups:
                                    </Box>
                                </Tooltip>
                                <LinearProgressLabelAndBuffer
                                    progress={(cutsByGroupNumber.totals.cut / (cutsByGroupNumber.totals.cut + cutsByGroupNumber.totals.notCut)) * 100}
                                    buffer={((currentDayOfWeek - daysToAdjustTargetDay) * 20) - hoursToRemoveFromTargetPercentage()}/>
                                <Box sx={{width: "200px", textAlign: "left"}}></Box>

                            </Stack>
                            <Stack key={"revenue"} sx={{mt: 2}}>
                                <Tooltip
                                    title={`$${cutsByGroupNumber.totals.revenue} / $${cutsByGroupNumber.totals.revenue + cutsByGroupNumber.totals.missingRevenue}`}>
                                    <Box sx={{width: "300px", textAlign: "left"}}>
                                        {`Revenue: $${cutsByGroupNumber.totals.revenue} / $${cutsByGroupNumber.totals.revenue + cutsByGroupNumber.totals.missingRevenue}`}
                                    </Box>
                                </Tooltip>
                                <Box>
                                    {`Unrealized Revenue: $${cutsByGroupNumber.totals.missingRevenue}`}
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>

                </>)
                }

            </Paper>
            <Stack direction={"row"}>
            <Paper
                elevation={10}
                sx={{
                    p: 1,
                    pt: 0,
                    m: 2,
                    mt: 4,
                    width: 360,
                    overflow: 'auto',
                    height: 750,
                }}
            >
                <Typography variant={"h5"} sx={{t: 0, pt: 2, mb: 2, position: "sticky", top: 0, backgroundColor: "white", zIndex: 1}}>
                    Recent Notes
                </Typography>
                    {recentNotesLoading && (
                        <CircularProgress />
                    )}
                    {lastThirtyNotes !== null && (
                        <>
                            {
                                lastThirtyNotes.map((note) => {
                                    return (
                                        <DashboardNotesCard key={note.id} noteData={note}/>
                                    )
                                })
                            }
                        </>
                    )}

            </Paper>
            <PastDueCuts claims={claims}></PastDueCuts>
            </Stack>
        </Box>
    )
}