import {
  Button,
  FormControl,
  InputLabel, ListItem,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, {useCallback, useEffect, useState} from "react";
import {db} from "../firebase-config";
import { collection, doc, getDocs, orderBy, query, serverTimestamp, setDoc} from "firebase/firestore";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {useSnackbar} from "notistack";
import Divider from "@mui/material/Divider";


export function OptimizeOrder(props) {
  const {tableData, claims, orderListID} = props
  const [optimizableGroups, setOptimizableGroups] = useState([]);
  const [selectedGroupsToOptimize, setSelectedGroupsToOptimize] = useState([]);
  const [selectedGroupToOptimize, setSelectedGroupToOptimize] = useState("");
  const [optimizationHistory, setOptimizationHistory] = useState([])
  const { enqueueSnackbar } = useSnackbar();

  const getOptimizationHistory = useCallback(async () => {
    const optimizationsHistoryQuery = query(collection(db, `accounts/${claims.accountID}/orderList/${orderListID}/actions/`),
      orderBy("created", "desc"))
    const optimizationHistoryResults = await getDocs(optimizationsHistoryQuery)
    const allOptimizationsData = []
    optimizationHistoryResults.forEach((doc)=> {
      const destination = doc.data()?.next[0]?.destination
      if (destination && (destination === "processRoutesGoogleMaps" || destination === "processRoutesWithORTOOLS")){
        allOptimizationsData.push({id:doc.id, ...doc.data()})
      }
    })
    return allOptimizationsData
  },[claims.accountID, orderListID])

  const optimizeGroups = async () => {
    const allOptimizationsData = await getOptimizationHistory()
    let allowOptimization = true
    for (let doc of allOptimizationsData) {
      if(doc.status === "NEW" || doc.status === "PROCESSING") {
        allowOptimization = !doc.dataShared.groupsToOptimize.some(group => selectedGroupsToOptimize.includes(group))
      }
    }
    if (allowOptimization) {
      const actionColRef = collection(db, `accounts/${claims.accountID}/orderList/${orderListID}/actions`)
      const newActionRef = doc(actionColRef)
      await setDoc(newActionRef, {
        eventIDShared: newActionRef.id,
        created: serverTimestamp(),
        updated: serverTimestamp(),
        dataShared: {
          groupsToOptimize: selectedGroupsToOptimize,
        },
        next: [
          {
            dataPrivate: {
              testManualRouteOnly: true
            },
            destination: "processRoutesGoogleMaps",
            queue: "optimize",
          },
          {
            destination: "processRoutesWithORTOOLS",
            queue: "optimize",
          },
          {
            destination: "processRoutesGoogleMaps",
            queue: "optimize",
          }
        ],
        status: "NEW",
      })
      enqueueSnackbar('Optimization Process Started', {variant: "success"})
      loadOptimizationHistory().then(()=> console.log('Optimization History Re-loaded'))
    } else {
      enqueueSnackbar('One of the selected groups is already being optimized.', {variant: "error"})
    }
  }

  const optimizeGroup = async () => {
    const allOptimizationsData = await getOptimizationHistory()
    let allowOptimization = true
    for (let doc of allOptimizationsData) {
      if(doc.status === "NEW" || doc.status === "PROCESSING") {
        allowOptimization = !doc.dataShared.groupsToOptimize.some(group => selectedGroupToOptimize === group)
      }
    }
    if (allowOptimization) {
      const actionColRef = collection(db, `accounts/${claims.accountID}/orderList/${orderListID}/actions`)
      const newActionRef = doc(actionColRef)
      await setDoc(newActionRef, {
        eventIDShared: newActionRef.id,
        created: serverTimestamp(),
        updated: serverTimestamp(),
        dataShared: {
          groupsToOptimize: [selectedGroupToOptimize],
        },
        next: [
          {
            dataPrivate: {
              testManualRouteOnly: true
            },
            destination: "processRoutesGoogleMaps",
            queue: "optimize",
          },
          {
            destination: "processRoutesWithORTOOLS",
            queue: "optimize",
          },
          {
            destination: "processRoutesGoogleMaps",
            queue: "optimize",
          }
        ],
        status: "NEW",
      })
      enqueueSnackbar('Optimization Process Started', {variant: "success"})
      loadOptimizationHistory().then(()=> console.log('Optimization History Re-loaded'))
    } else {
      enqueueSnackbar('The selected group is already being optimized.', {variant: "error"})
    }
  }

  const loadOptimizationHistory = useCallback(async () => {
    const allOptimizationsData = await getOptimizationHistory()
    setOptimizationHistory(allOptimizationsData)
  }, [getOptimizationHistory])


  useEffect(()=> {
    loadOptimizationHistory().then(()=>console.log('Optimization History Loaded'))
  }, [loadOptimizationHistory])

  useEffect(() => {
    if (tableData.length > 0) {
      const groupNames = tableData.map((row) => row.group);
      const uniqueGroups = [...new Set(groupNames)].sort((a,b) => a - b)
      setSelectedGroupsToOptimize([])
      setSelectedGroupToOptimize("")
      setOptimizableGroups(uniqueGroups);
    }
  }, [tableData]);

  return (
    <Paper
      elevation={4}
      sx={{
        p: 4
      }}
    >
      <Typography>
        <b>Optimize Single Group</b> <br/>
        Optimize items within the same group.
      </Typography>
      <Stack
        direction={"column"}
        rowGap={3}
        sx={{
          mt:2
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="optimize-order-select" >Optimize Group</InputLabel>
          <Select
            labelId="optimize-order-select"
            id="optimize-order-select"
            label="Optimize Group"
            name="Optimize Group"
            multiple={false}
            onChange={(e) => {
              setSelectedGroupToOptimize(
                e.target.value,
              )
            }}
            value={selectedGroupToOptimize}
          >
            <MenuItem selected disabled>
              Select Group
            </MenuItem>
            {
              optimizableGroups.filter(group => group !== null).map((group, index) => (
                  <MenuItem
                    value={group}
                    key={index}
                  >
                    {group}
                  </MenuItem>
                )
              )
            }
          </Select>
        </FormControl>
        <Button
          color="primary"
          variant="contained"
          size={"large"}
          fullWidth
          onClick={optimizeGroup}
          disabled={!selectedGroupToOptimize}
        >
          Optimize Single Group
        </Button>
        <Divider/>
        <Typography
        >
          <b>Optimize Multiple Groups</b><br/>
          Items will move between all groups selected.
        </Typography>

        <FormControl fullWidth>
          <InputLabel id="optimize-order-select-multiple" >Optimize Groups</InputLabel>
          <Select
            labelId="optimize-order-select-multiple"
            id="optimize-order-select-multiple"
            label="Optimize Groups"
            name="Optimize Groups"
            multiple={true}
            onChange={(e) => {
              setSelectedGroupsToOptimize(
                e.target.value,
              )
            }}
            value={selectedGroupsToOptimize}
          >
            <MenuItem selected disabled>
              Select Groups
            </MenuItem>
            {
              optimizableGroups.filter(group => group !== null).map((group, index) => (
                  <MenuItem
                    value={group}
                    key={index}
                  >
                    {group}
                  </MenuItem>
                )
              )
            }
          </Select>
        </FormControl>

        <Button
          color="primary"
          variant="contained"
          size={"large"}
          fullWidth
          onClick={optimizeGroups}
          disabled={!(selectedGroupsToOptimize.length > 1) }
        >
          Optimize Multiple Groups
        </Button>
        <Divider/>
        <List>
          <ListSubheader>
            Optimization History
          </ListSubheader>
            {optimizationHistory.length && optimizationHistory.map((doc)=> {
              const stage = doc.next[0].destination === "processRoutesWithORTOOLS" ? "(Geolocation Optimization)": "(Road Mapping Optimization)"
              let status = doc.status.toLowerCase()
              status = status[0].toUpperCase() + status.slice(1)
              return (
                  <ListItem key={doc.id}>
                    <ListItemText
                      primary={`Group${doc.dataShared.groupsToOptimize.length > 1 ? "s" : ""} ${doc.dataShared.groupsToOptimize.join()} ${stage}`}
                      secondary={`Status: ${status} - ${doc.created.toDate().toLocaleDateString("en-US", {year: 'numeric', month:'long', day:'numeric', hour:'numeric', minute:'numeric'})}`}
                    />
                  </ListItem>
              )
            })}
        </List>

      </Stack>

    </Paper>
  )
}