import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
  IconButton,
  FormControl,
  InputLabel, Paper,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import {
  collection,
  getDoc,
  query,
  doc,
  getDocs,
  where,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Notes from "../../components/ClientNotes";
import { db } from "../../firebase-config";


const OrderDetails = ({claims, user}) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState(null);
  const [listLoading, setListLoading] = useState(true);
  const [clientDetails, setClientDetails] = useState({
    firstName: "",
    lastName: "",
    address: "",
    customizedAddress: "",
  });
  const [employeesLists, setEmployeesLists] = useState(null);
  const [values, setValues] = useState({
    employeeID: [],
    status: true,
  });

  const checkEmployeeCut = useCallback( async (individualCutsData)=> {
    const employees = individualCutsData.employees
    if (employees.length) {
      setValues(values => {
        return {
          status: values.status,
          employeeID: employees
        }
      })
    }
  }, [])

  const getClientInfo = useCallback(async (clientId) => {
    const clientRef = doc(db, `accounts/${claims.accountID}/clients/${clientId}`);
    const clientDoc = await getDoc(clientRef);
    if (clientDoc.exists() === false) {
      enqueueSnackbar("Client not found", { variant: "error" });
      return
    }
    setClientDetails({
      firstName: clientDoc.data().firstName,
      lastName: clientDoc.data().lastName,
      address: clientDoc.data().address,
      customizedAddress: clientDoc.data().customizedAddress || "",
    });
  },[claims.accountID, enqueueSnackbar]);

  const getEmployees = useCallback(async () => {
    const employeesRef = query(
      collection(db, `accounts/${claims.accountID}/employees/`),
      where("active", "==", true)
    );
    const employeesDocs = await getDocs(employeesRef);
    const employeeData = [];
    employeesDocs.forEach((doc) => {
      employeeData.push({...doc.data(), id: doc.id});
    })
    setEmployeesLists(employeeData);
    setListLoading(false);
  }, [claims.accountID]);


  const initialLoader = useCallback(async () => {
    const individualCutsRef = doc(db, `accounts/${claims.accountID}/individualCuts/${id}`);
    const individualCutsDoc = await getDoc(individualCutsRef)
    const individualCutsData = {...individualCutsDoc.data(), id: individualCutsDoc.id}

    getClientInfo(individualCutsData.clientID);
    setOrderDetails(individualCutsData);
    checkEmployeeCut(individualCutsData);
    getEmployees();
  }, [claims.accountID, id, getClientInfo, getEmployees, checkEmployeeCut]);

  useEffect(() => {
    if (id && claims.accountID) {
      void initialLoader();
    }
  }, [id, claims.accountID, getClientInfo, getEmployees, checkEmployeeCut, initialLoader]);



  const handleSubmit = async (event) => {
    event.preventDefault();
    const docRef = doc(db, `accounts/${claims.accountID}/individualCuts/${id}`);
    updateDoc(docRef, {
      cut: true,
      employees: [...values.employeeID],
      cutDate: serverTimestamp(),
    }).then(() => {
      enqueueSnackbar("Order updated", { variant: "success" });
      navigate(`/orders`);
    });
  };

  return (
    <Box
    >
      {!listLoading ? (
        <Box
          sx={{
            maxWidth: "600px",
            mx: "auto",
            pt: "25px",
          }}
        >
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/orders")}
            size="small"
          >
            <ArrowBackIcon fontSize="small" />
            Back
          </IconButton>
          <Paper
            elevation={3}
            sx={{
              mx: 1,
              mt: 2,
              pt: 1,
              pb: 2,
            }}
          >
            <form onSubmit={handleSubmit}>
              <Box>
                <h2>Client Details</h2>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                rowGap={3}
                sx={{
                  mb: 1,
                  px: 2,
                }}
              >
                <TextField
                  id="outlined-required-name"
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={`${clientDetails.firstName} ${clientDetails.lastName}`}
                  fullWidth
                />
                {clientDetails.customizedAddress ? (
                    <TextField
                      id="outlined-required-customized-address"
                      InputProps={{
                        readOnly: true,
                      }}
                      label="Customized Address"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={clientDetails?.customizedAddress}
                      fullWidth
                    />

                  )
                  :
                  null
                }
                <TextField
                  id="outlined-required-address"
                  InputProps={{
                    readOnly: true,
                  }}
                  label="Address"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={clientDetails?.address}
                  fullWidth
                />
                <FormControl
                  fullWidth
                >
                  <InputLabel id="simple-select-label">
                    Job completed by
                  </InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    label="Job completed by"
                    name="employeeID"
                    multiple={true}
                    required
                    value={values.employeeID}
                    sx={{
                      '& #simple-select': {
                        whiteSpace: 'normal',
                      },
                    }}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        employeeID: e.target.value,
                      })
                    }}
                  >
                    {employeesLists?.map((employee) => (
                      <MenuItem value={employee.id} key={employee.id}

                      >
                        {employee.firstName} {employee.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  size={'large'}
                >
                  Save
                </Button>
              </Box>
            </form>
          </Paper>
          {id && (
            <Box sx={{
              px:1,
              mt: 5,
            }}>
              <Notes clientID={orderDetails?.clientID} claims={claims} user={user} />
            </Box>
          )}
        </Box>
      ) : (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          padding={4}
          justifyContent="center"
        >
          <CircularProgress color="primary" size={50} />
        </Grid>
      )}
    </Box>
  );
};

export default OrderDetails;
