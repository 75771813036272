import React, {useState, useEffect, useCallback} from "react";
import {
  Box,
} from "@mui/material";

import {
  doc,
  collection,
  getDocs,
  query,
  orderBy,
  serverTimestamp,
  addDoc,
  getDoc,
  where,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase-config";
import { useSnackbar } from "notistack";
import {WeeklyOrdersDataGrid} from "../components/WeeklyOrdersDataGrid";


const initialGroupTracker = {
  1:0,
  2:0,
  3:0,
  4:0,
  5:0,
  6:0,
  7:0,
  8:0,
  9:0,
  10:0,
  11:0,
}

export default function CreateWeeklyOrder({claims}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [startWeekDay, setStartWeekDay] = useState(1);
  const [weekDate, setWeekDate] = useState(null);
  const [remaining, setRemaining] = useState(0);
  const [, setErrors] = useState({});
  const [groupTracker, setGroupTracker] = useState(initialGroupTracker);

  const loadDataGrid = async (accountID) => {
    setListLoading(true);
    const q = query(collection(db, `/accounts/${accountID}/clients`),
      where('active', '==', true),
      orderBy("created", "desc"));
    const querySnapshot = await getDocs(q);
    let tempRows = [];
    let placement = 1;
    querySnapshot.forEach((doc) => {
      const data = {
        id: doc.id,
        clientID: doc.id,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        group: null,
        phone: doc.data().phone,
        email: doc.data().email,
        price: doc.data().price,
        address: doc.data().address || "",
        created: new Date(doc.data().created.seconds * 1000).toLocaleString(
          "en-US"
        ),
        placement: placement
      };
      placement = placement + 1
      tempRows.push(data);
    });
    setTableData([...tempRows]);
    setListLoading(false);
  };

  useEffect(() => {
    const getStartWeekDayDoc = async (accountID) => {
      const docRef = doc(db, `accounts/${accountID}/settings/startDayOfWeek`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setStartWeekDay([
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ].indexOf(docSnap.data().startDayOfWeek));
      }
    };
    if (claims?.accountID) {
      void loadDataGrid(claims.accountID);
      void getStartWeekDayDoc(claims.accountID);
      setGroupTracker(initialGroupTracker);
    }
  }, [claims.accountID, navigate]);

  const onWeekSelection = useCallback((dateStartOfWeek) => {
    // passed up from WeekSelector
    setWeekDate(dateStartOfWeek);
  }, [])


  const checkIfOrderListForThatWeekExists = async () => {
    const nextDay = new Date(weekDate.getTime() + 60 * 60 * 24 * 1000);
    const prevDay = new Date(weekDate.getTime() - 60 * 60 * 24 * 1000);
    const q = query(
      collection(db, `/accounts/${claims.accountID}/orderList`),
      where("weekStartDate", "<", nextDay),
      where("weekStartDate", ">", prevDay)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.size !== 0
  };


  const saveWeeklyOrder = async () => {
    try {
      if (!weekDate) {
        setErrors({ date: "Select Week for order" });
        return;
      }
      setSaving(true);
      const isOrderListExistingForThatWeek = await checkIfOrderListForThatWeekExists()
      if (isOrderListExistingForThatWeek) {
        enqueueSnackbar("Order List for that week already exists", { variant: "error" });
        setSaving(false);
        setRemaining(0)
        return
      }
      const orderRef = collection(db, `/accounts/${claims.accountID}/orderList`);
      const OrderData = await addDoc(orderRef, {
        weekStartDate: weekDate,
        created: serverTimestamp(),
        totalOrder: tableData?.length || 0,
      });
      if (OrderData?.id) {
        try {
          for (const row of tableData) {
            const collectionRef = collection(db, `/accounts/${claims.accountID}/individualCuts`);
            let temp = {
              cut: false,
              employees: [],
              cutDate: null,
              clientID: row.clientID,
              weekStartDate: weekDate,
              created: serverTimestamp(),
              group: row.group,
              price: row.price,
              orderListID: OrderData.id,
              placement: row.placement,
            };
            await addDoc(collectionRef, temp)
          }
          enqueueSnackbar("Order created", { variant: "success" });
          setSaving(false);
          navigate(`/order-list`);
        } catch(e){
          console.log("error", e);
          setSaving(false);
          enqueueSnackbar("Error in order creation", { variant: "error" });
        }
      }
    } catch (error) {
      enqueueSnackbar("Error in order creation", { variant: "error" });
    }
  };

  return (
    <Box>
      <WeeklyOrdersDataGrid
        tableData={tableData}
        setTableData={setTableData}
        groupTracker={groupTracker}
        setGroupTracker={setGroupTracker}
        listLoading={listLoading}
        remaining={remaining}
        setRemaining={setRemaining}
        saving={saving}
        saveWeeklyOrder={saveWeeklyOrder}
        startWeekDay={startWeekDay}
        onWeekSelection={onWeekSelection}
        createOrEdit={"create"}
      />
    </Box>
  );
}
