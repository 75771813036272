import {
  Box,
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl, InputLabel,
  LinearProgress, MenuItem, Select
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  gridColumnVisibilityModelSelector,
  GridEvents,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import WeekSelector from "./WeekSelector";
const INITIAL_GROUPING_COLUMN_MODEL = ["group"];

const useKeepGroupingColumnsHidden = (
  apiRef,
  columns,
  initialModel,
  leafField
) => {
  const prevModel = useRef(initialModel);

  useEffect(() => {
    apiRef?.current?.subscribeEvent(
      GridEvents.rowGroupingModelChange,
      (newModel) => {
        const columnVisibilityModel = {
          ...gridColumnVisibilityModelSelector(apiRef),
        };

        newModel.forEach((field) => {
          if (!prevModel.current.includes(field)) {
            columnVisibilityModel[field] = false;
          }
        });
        prevModel.current.forEach((field) => {
          if (!newModel.includes(field)) {
            columnVisibilityModel[field] = true;
          }
        });
        apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
        prevModel.current = newModel;
      }
    );
  }, [apiRef]);

  return useMemo(
    () =>
      columns.map((colDef) =>
        initialModel.includes(colDef.field) ||
        (leafField && colDef.field === leafField)
          ? { ...colDef, hide: true }
          : colDef
      ),
    [columns, initialModel, leafField]
  );
};


export function WeeklyOrdersDataGrid(props) {
  const [selectionModel, setSelectionModel] = useState([]);
  const apiRef = useGridApiRef();

  const {
    tableData,
    setTableData,
    groupTracker,
    setGroupTracker,
    listLoading,
    remaining,
    setRemaining,
    saving,
    saveWeeklyOrder,
    startWeekDay,
    onWeekSelection,
  } = props;


  const handleRemoveIconClick = (id, rowData) => (event) => {
    event.stopPropagation();
    const rowPlacement = rowData.placement
    const rowGroup = rowData.group
    const tempRows = tableData.map((row) => {
      if (id === row.id) {
        row.group = null;
      } else if (row.group === rowGroup) {
        if (row.placement > rowPlacement) {
          row.placement = row.placement - 1;
        }
      }
      return row;
    });
    setGroupTracker({
      ...groupTracker,
      [rowGroup]: groupTracker[rowGroup] - 1
    });
    setTableData(tempRows);
    setSelectionModel([]);
  };

  const handleIncreasePlacement = (id, rowData) => (event) => {
    event.stopPropagation();
    const rowPlacement = rowData.placement
    const rowGroup = rowData.group
    if (rowPlacement === 1) return
    const tempRows = tableData.map((row) => {
      if (id === row.id) {
        row.placement = row.placement - 1;
      } else if (row.group === rowGroup) {
        if (row.placement === rowPlacement - 1) {
          row.placement = row.placement + 1;
        }
      }
      return row;
    });
    setTableData(tempRows);
    setSelectionModel([]);
  };

  const handleDecreasePlacement = (id, rowData) => (event) => {
    event.stopPropagation();
    const rowPlacement = rowData.placement
    const rowGroup = rowData.group
    if (groupTracker[rowGroup] === rowPlacement) return
    const tempRows = tableData.map((row) => {
      if (id === row.id) {
        row.placement = row.placement + 1;
      } else if (row.group === rowGroup) {
        if (row.placement === rowPlacement + 1) {
          row.placement = row.placement - 1;
        }
      }
      return row;
    });
    setTableData(tempRows);
    setSelectionModel([]);
  };

  const columnsData = [
    { field: "actions", headerName: "Remove", width: 75, type: "actions", getActions: (params) => {
        return [
          <GridActionsCellItem
            disabled={!params.row?.group}
            sx={{ display: !params.row?.group ? "none" : "block" }}
            icon={<PersonRemoveIcon />}
            label="Ungroup"
            onClick={handleRemoveIconClick(params.id, params.row)}
            color="error"
          />,
        ];
      },
    },

    { field: "placement", headerName: "Order", width: 60,  align: "center" },
    { field: "placementMove", headerName: "Reorder", width: 75, type: "actions", getActions: (params) => {
        return [
          <GridActionsCellItem
            disabled={!params.row?.group}
            sx={{ display: !params.row?.group ? "none" : "block" }}
            icon={<ArrowUpward />}
            label="Increase Placement"
            onClick={handleIncreasePlacement(params.id, params.row)}
            color="success"
          />,
          <GridActionsCellItem
            disabled={!params.row?.group}
            sx={{ display: !params.row?.group ? "none" : "block" }}
            icon={<ArrowDownward />}
            label="Decrease Placement"
            onClick={handleDecreasePlacement(params.id, params.row)}
            color="error"
          />,
        ];
      },
    },
    { field: "address", headerName: "Address", width: 200},
    { field: "firstName", headerName: "First Name", width: 125},
    { field: "lastName", headerName: "Last Name", width: 125},
    { field: "cut", headerName: "Cut Status",  width: 150,   type: "boolean" },
    { field: "group",  headerName: "Group",  width: 75},

  ]

  const columns = useKeepGroupingColumnsHidden(
    apiRef,
    columnsData,
    INITIAL_GROUPING_COLUMN_MODEL
  );


  const removePlacementGapsOnAssign =  (currentRows, placement, group, groupTrackerInternal) => {
    const rowPlacement = placement
    const rowGroup = group
    const tempRows = currentRows.map((row) => {
      if (row.group === rowGroup) {
        if (row.placement > rowPlacement) {
          row.placement = row.placement - 1;
        }
      }
      return row;
    });
    groupTrackerInternal = {
        ...groupTrackerInternal,
        [rowGroup]: groupTrackerInternal[rowGroup] - 1
      }
    return [tempRows, groupTrackerInternal];
  }

  const onGroupAssign = (e) => {
    let changingRows = [];
    let allRows = [...tableData];
    let groupTrackerInternal = {...groupTracker};
    let ignoreInSelectionModel = []
    for (const row of tableData) {
      if (selectionModel.includes(row.id)) {
        if(row.group !== e.target.value){
          changingRows.push(row);
        } else {
          ignoreInSelectionModel.push(row.id);
        }
      }
    }
    const cleanSelectionModel = selectionModel.filter(id => !ignoreInSelectionModel.includes(id));
    changingRows.sort((a, b) => b.placement - a.placement)
    for (const item of changingRows) {
      if (item.group){
        [
          allRows, groupTrackerInternal
        ] = removePlacementGapsOnAssign(allRows, item.placement, item.group, groupTrackerInternal)
      }
    }
    let count = 0
    const tempRows = allRows.map((row) => {
      if (cleanSelectionModel.includes(row.id)) {
        count = count + 1
        row.group = e.target.value;
        row.placement = groupTrackerInternal[e.target.value] + count;
      }
      return row;
    });
    setGroupTracker({
      ...groupTrackerInternal,
      [e.target.value]: groupTrackerInternal[e.target.value] + count
    });
    setTableData(tempRows);
    setSelectionModel([]);
    console.log(groupTrackerInternal)
  };

  const saveClick = () => {
    const remaining = tableData.filter((e) => e.group === null);
    if (remaining.length) {
      setRemaining(remaining.length);
      return;
    }
    saveWeeklyOrder();
  };


  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        px:3,
        pt:3,
      }}>
        <Box sx={{width: 200}}>
          {selectionModel && selectionModel.length ? (
            <FormControl fullWidth>
              <InputLabel id="assign-to-group" >Assign to Group</InputLabel>
              <Select
                labelId="assign-to-group"
                id="assign-to-group-select"
                label="Assign to Group"
                name="Assign to Group"
                onChange={onGroupAssign}
              >
                <MenuItem selected disabled>
                  Select Any Group
                </MenuItem>
                {Array.from({ length: 11 }, (_, i) => i + 1).map(
                  (item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          ) : null}
        </Box>
        {startWeekDay && onWeekSelection ? (
          <WeekSelector startWeekDay={startWeekDay} onChange={onWeekSelection}/>
        )
        : null
        }
        <Button
          color="primary"
          variant="contained"
          onClick={saveClick}
          disabled={saving}
          size={"large"}
          sx={{width: 200}}
        >
          Save
          {saving && <CircularProgress color="primary" size={20} />}
        </Button>

      </Box>

      <Box
      sx={{
        p: 3
      }}
    >
      <Box
        sx={{
          height: 1200,
          maxWidth: "100%",
          mx: "auto",
        }}
      >

        <DataGridPro
          checkboxSelection={true}
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          isRowSelectable={(params) =>
            (Boolean(params.row.id))
          }
          rows={tableData}
          apiRef={apiRef}
          columns={columns}
          initialState={{
            rowGrouping: {
              model: INITIAL_GROUPING_COLUMN_MODEL,
            },
          }}
          experimentalFeatures={{
            rowGrouping: true,
          }}
          loading={listLoading}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          hideFooterRowCount={true}
        />
      </Box>
    </Box>
      <Dialog
        open={Boolean(remaining)}
        onClose={() => setRemaining(0)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {remaining} {remaining > 1 ? "clients" : "client"} not grouped.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setRemaining(0);
            }}
          >
            Assign to group
          </Button>
          <Button onClick={saveWeeklyOrder} disabled={saving}>
            Skip and Save{" "}
            {saving && <CircularProgress color="primary" size={20} />}
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  )
}