import React, {useState, useEffect, useCallback} from "react";
import { useNavigate } from "react-router-dom";
import {TextField, Box, LinearProgress, Button, Stack} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {sub, startOfMonth, endOfMonth, isEqual, subDays, addDays} from 'date-fns'

import {
  DataGridPro,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import {collection, getDoc, getDocs, query, where, doc, serverTimestamp, limit, addDoc} from "firebase/firestore";
import {db} from "../firebase-config";
import {useSnackbar} from "notistack";
import AddIcon from "@mui/icons-material/Add";
import MUILink from "@mui/material/Link";

const initialColumnVisibilityModel = {
  id: false,
  firstName: true,
  lastName: true,
  address: true,
  created: true,
  clientID: false,
  totalCuts: true,
  pricePerCut: true,
  totalBeforeTax: true,
  invoiceQBID: true,
  customerQBID: true,
  emailSent: true,
}

export default function Invoices({claims}) {
  const apiRef = useGridApiRef();
  const [tableData, setTableData] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [dateSelection, setDateSelection] = useState(new Date());
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [previousMonth, setPreviousMonth] = useState(false);
  const [disableInvoiceCreate, setDisableInvoiceCreate] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(initialColumnVisibilityModel);

  const handleColumnVisibility = useCallback((invoiceType) => {
    if (invoiceType === "preview") {
      setColumnVisibilityModel({
        id: false,
        firstName: true,
        lastName: true,
        address: true,
        created: false,
        clientID: false,
        totalCuts: true,
        pricePerCut: true,
        totalBeforeTax: true,
        invoiceQBID: false,
        customerQBID: true,
        emailSent: false,
      });
    } else {
      setColumnVisibilityModel({
        id: false,
        firstName: true,
        lastName: true,
        address: true,
        created: true,
        clientID: false,
        totalCuts: true,
        pricePerCut: true,
        totalBeforeTax: true,
        invoiceQBID: true,
        customerQBID: true,
        emailSent: true,
      });
    }
  }, []);

  const extractDataFromQuery = async (queryRef) => {
    const results = await getDocs(queryRef)
    let resultsData = []
    results.forEach((doc)=> {
      resultsData.push({...doc.data(), id: doc.id})
    })
    return resultsData
  }


  const loadPreviewInvoices = useCallback( async () => {
    console.log("loadPreviewInvoices");
    setTableData([])
    const individualCutsQuery = query(collection(db, `accounts/${claims.accountID}/individualCuts`),
      where("cutDate", ">", startOfMonth(dateSelection)),
      where("cutDate", "<", endOfMonth(dateSelection))
    )
    const individualCutsData = await extractDataFromQuery(individualCutsQuery)
    if (!individualCutsData.length) {
      return {exists: false}
    }
    const clientsActiveQuery = query(
      collection(db, `accounts/${claims.accountID}/clients`),
      where("active", "==", true)
    )
    const clientsActiveData = await extractDataFromQuery(clientsActiveQuery)
    const clientsActiveObj = {}
    for (const client of clientsActiveData) {
      clientsActiveObj[client.id] = client
    }
    const cutsWithoutClients = [] // missing because not active or deleted
    let tempRows = [];
    for (const cut of individualCutsData) {
      const exists = Object.hasOwn(clientsActiveObj, cut.clientID)
      if (exists){
        const clientData = clientsActiveObj[cut.clientID]
        if (clientData.doNotInvoice) continue;
        tempRows.push({
            id: cut.id,
            firstName: clientData.firstName,
            lastName: clientData.lastName,
            address: clientData.customizedAddress ? clientData.customizedAddress : clientData.address,
            created: "",
            clientID: cut.clientID,
            totalCuts: 0,
            pricePerCut: clientData.price,
            totalBeforeTax: 0,
            invoiceQBID: "",
            emailSent: false,
            email: clientData.email,
            customerQBID: clientData.customerQBID,
          })
      } else {
        cutsWithoutClients.push(cut)
      }
    }
    const promises = []
    for (const cut of cutsWithoutClients) {
      const docRef = doc(db, `/accounts/${claims.accountID}/clients/${cut.clientID}`);
      promises.push(getDoc(docRef).then((clientDoc) => {
          if (clientDoc.exists()) {
            const clientData = clientDoc.data()
            tempRows.push({
              id: cut.id,
              firstName: clientData.firstName,
              lastName: clientData.lastName,
              address: clientData.customizedAddress ? clientData.customizedAddress : clientData.address,
              created: "",
              clientID: cut.clientID,
              totalCuts: 0,
              pricePerCut: clientData.price,
              totalBeforeTax: 0,
              invoiceQBID: "",
              emailSent: false,
              email: clientData.email,
              customerQBID: clientData.customerQBID,
            })
          }
        }
      ))
    }
    await Promise.all(promises)

    const clientIDs = []
    const finalRows = []
    for (const item of tempRows) {
      if (!clientIDs.includes(item.clientID)){
        clientIDs.push(item.clientID)
        const totalCuts = tempRows.filter(field=>field.clientID === item.clientID).length
        finalRows.push({
          ...item,
          totalCuts: totalCuts,
          totalBeforeTax: totalCuts * item.pricePerCut,
        })
      }
    }
    // Find items with duplicate customerQBID and add them together
    const groupedByProperty = []
    let incrementID = 1
    for (const item of finalRows) {
      const property = {id: incrementID , address: item.address, totalCuts: item.totalCuts, totalBeforeTax: item.totalBeforeTax, pricePerCut: item.pricePerCut}
      if (groupedByProperty.length === 0) {
        groupedByProperty.push({...item, clientProperties: [property]})
      } else {
        const index = groupedByProperty.findIndex(field => field.customerQBID === item.customerQBID)
        if (index === -1) {
          groupedByProperty.push({...item, clientProperties: [property]})
        } else {
          groupedByProperty[index].totalCuts += item.totalCuts
          groupedByProperty[index].totalBeforeTax += item.totalBeforeTax
          groupedByProperty[index].pricePerCut = 0
          groupedByProperty[index].address = groupedByProperty[index].address.concat(", " + item.address)
          groupedByProperty[index].clientProperties = [...groupedByProperty[index].clientProperties, property]

        }
      }
      incrementID = incrementID + 1
    }

    setTableData([...groupedByProperty])
    handleColumnVisibility("preview")
    setListLoading(false);
    setDisableInvoiceCreate(false)
    return {exists: true}
  },[claims.accountID, dateSelection, handleColumnVisibility])

  const loadInvoices = useCallback(async () => {
    setTableData([])
    const invoicesForMonthQuery = query(
      collection(db, `/accounts/${claims.accountID}/invoices`),
      where("ordersInvoicedForMonth", "==", dateSelection.getMonth()),
      where("ordersInvoicedForYear", "==", dateSelection.getFullYear())
    );
    const invoicesData = await extractDataFromQuery(invoicesForMonthQuery)
    if (!invoicesData.length) {
      return {exists: false}
    }
    let tempRows = [];
    for (const invoice of invoicesData) {
        tempRows.push({
          id: invoice.id,
          firstName: invoice.firstName,
          lastName: invoice.lastName,
          address: invoice.address,
          created: new Date(invoice.created.seconds * 1000).toLocaleString("en-US"),
          clientID: invoice.clientID,
          totalCuts: invoice.totalCuts,
          pricePerCut: invoice.pricePerCut,
          totalBeforeTax: invoice.totalBeforeTax,
          invoiceQBID: invoice.invoiceQBID,
          customerQBID: invoice.customerQBID,
          emailSent: invoice.emailSent,
          clientProperties: invoice.clientProperties,
        })
    }
    setTableData([...tempRows]);
    handleColumnVisibility("invoices");
    setListLoading(false);
    return {exists: true}
  }, [dateSelection, claims.accountID, handleColumnVisibility])

  const isPreviousMonth = (date) => {
    const previousMonth = sub(new Date(), {months: 1})
    return date.getMonth() === previousMonth.getMonth() && date.getFullYear() === previousMonth.getFullYear()
  }

  const loadDataGrid = useCallback(async () => {
    // Date adaptor creates a localized date, different from just calling new Date()
    setListLoading(true);
    const invoiceResults = await loadInvoices()
    if (invoiceResults.exists) return
    // check if previous month is selected, in which case, loadPreviewInvoices
    if (previousMonth) {
      await loadPreviewInvoices()
    }
    setListLoading(false)
  }, [  loadInvoices, loadPreviewInvoices, previousMonth]);

  useEffect( () => {

    if (claims?.accountID) {
      void loadDataGrid();
    } else {
      setListLoading(false);
    }
    return () => {
      setTableData([])
      setColumnVisibilityModel(initialColumnVisibilityModel)
      setListLoading(true);
      setDisableInvoiceCreate(true)

    }
  }, [loadDataGrid, loadInvoices, dateSelection, claims.accountID,  navigate]);



  const columns = [
    {
      field: "invoiceQBID",
      headerName: "Quickbooks Invoice ID",
      width: 175,
      renderCell: ({row}) => (
        <MUILink
          href={`https://app.qbo.intuit.com/app/invoice?txnId=${row.invoiceQBID}`}
          target="_blank"
          rel="noopener"
        >
          {row.invoiceQBID}
        </MUILink>
      )
    },
    {
      field: "emailSent",
      headerName: "Email Sent",
      width: 100,
      type: "boolean",
    },
    {
      field: "address",
      headerName: "Addresses",
      width: 150,
    },
    {
      field: "properties",
      headerName: "# of Properties",
      width: 115,
      align: "center",
      renderCell: (params) => {
        return params.row.clientProperties.length
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 100,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 100,
    },
    {
      field: "totalCuts",
      headerName: "Total Cuts",
      width: 90,
      align: "center",
    },
    {
      field: "pricePerCut",
      headerName: "Price per Cut",
      width: 100,
      valueFormatter: (params) => {
        if (params.value) {
          return `$${params.value.toFixed(2)}`
        } else {
          return ""
        }
        // return `$${params.value.toFixed(2)}`
      },
    },
    {
      field: "totalBeforeTax",
      headerName: "Total Before Tax",
      width: 125,
      valueFormatter: (params) => {
        return `$${params.value.toFixed(2)}`
      },
    },
    {
      field: "customerQBID",
      headerName: "Client Quickbooks ID",
      width: 150,
      align: "center",
      renderCell: ({row}) => (
        <MUILink
          href={`https://app.qbo.intuit.com/app/customerdetail?nameId=${row.customerQBID}`}
          target="_blank"
          rel="noopener"
        >
          {row.customerQBID}
        </MUILink>
      )
    },

  ]

  const DetailPanelContent = ({row: rowProp}) => {
    const columnsInternal = [
      {
        field: "address",
        headerName: "Addresses",
        width: 250,
      },
      {
        field: "totalCuts",
        headerName: "Total Cuts",
        width: 100,
      },
      {
        field: "pricePerCut",
        headerName: "Price per Cut",
        width: 100,
        valueFormatter: (params) => {
          return `$${params.value.toFixed(2)}`
        },
      },
      {
        field: "totalBeforeTax",
        headerName: "Total Before Tax",
        width: 125,
        valueFormatter: (params) => {
          return `$${params.value.toFixed(2)}`
        },
      },
    ]

    return (
      <Stack sx={{
        p: 3,
        height: 1,
        boxSizing: 'border-box',
        backgroundColor: "grey.alphaLight",
      }}
        direction="column"
      >

      <Box sx={{ width: 1, height: 800 }}>
        <DataGridPro
          density="compact"
          columns={columnsInternal}
          rows={rowProp.clientProperties}
          hideFooter
          sx={{
            border:0,
          }}

        />
      </Box>
      </Stack>
    )
  }
  const getDetailPanelContent = useCallback(
    ({ row }) => <DetailPanelContent row={row} />,
    [],
  );

  const getDetailPanelHeight = useCallback(() => 400, []);



  const createInvoices = async () => {
    const now = Date.now()
    const yesterday = subDays(now, 1)
    const tomorrow = addDays(now, 1)
    const actionsWithin24Hours = query(
      collection(db, `/accounts/${claims.accountID}/actions`),
      where("created", ">", yesterday),
      where("created", "<", tomorrow),
      where("type", "==", "createInvoicesForPreviousMonth"),
      limit(1)
    );
    const actionsWithin24HoursResults = await getDocs(actionsWithin24Hours);
    if (actionsWithin24HoursResults.empty){
      const actionsCollection = collection(db, `accounts/${claims.accountID}/actions`);
      await addDoc(actionsCollection,{
        type: "createInvoicesForPreviousMonth",
        created: serverTimestamp(),
        updated: serverTimestamp(),
        queue: "invoices",
        status: "NEW",
      })
      enqueueSnackbar('Invoice creation process started. Please check back in one hour.', {variant: 'success'})
    } else {
      enqueueSnackbar('Invoice creation already started.', {variant: 'error'})
    }
    }



  return (
    <Box >
      <Box sx={{
        display: "flex",
        justifyContent: 'left',
        columnGap: 2,
        p:3,
        pb:0,
      }}>
        <DatePicker
          views={['year', 'month']}
          label="Year and Month"
          minDate={new Date('2022-01-02')}
          maxDate={new Date('2032-06-01')}
          disableFuture={true}
          value={dateSelection}
          onChange={(newValue) => {
            if (isEqual(newValue, dateSelection)) {
              return
            }
            setDisableInvoiceCreate(true)
            setPreviousMonth(isPreviousMonth(newValue))
            setDateSelection(newValue);
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
        <Button
          color="primary"
          variant={"contained"}
          size={"large"}
          startIcon={<AddIcon />}
          onClick={() => createInvoices()}
          disabled={disableInvoiceCreate}
        >
          Create Invoices
        </Button>
      </Box>
      <Box
        sx={{
          p: 3
        }}
      >
        <Box
          sx={{
            height: 1200,
            maxWidth: "100%",
            mx: "auto",
          }}
        >
          <DataGridPro
            apiRef={apiRef}
            columns={columns}
            rows={tableData}
            loading={listLoading}
            components={{
              LoadingOverlay: LinearProgress,
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            rowThreshold={0}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}

          />
        </Box>
      </Box>
    </Box>
  );
}
