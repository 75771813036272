import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import {CssBaseline, ThemeProvider} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import {blue, red} from "@mui/material/colors";
import { createTheme } from '@mui/material/styles';


const primaryColor = blue
const secondaryColor = red

const theme = createTheme({
  palette: {
    primary: {
      50: primaryColor[50],
      main: primaryColor[700],

    },
    secondary: {
      50: secondaryColor[50],
      main: secondaryColor[700],
    },
    grey: {
      alphaLight: "rgba(0, 0, 0, 0.02)"
    }
  },
})

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <SnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
            </LocalizationProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </CssBaseline>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
