import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  orderBy, doc, deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, LinearProgress,
} from "@mui/material";

import {
  DataGridPro,
  useGridApiRef,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";

export default function ClientsList({claims}) {
  const [tableData, setTableData] = useState([]);
  const apiRef = useGridApiRef();
  let navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [listLoading, setListLoading] = useState(true);

  const loadDataGrid = async (accountID) => {
    setListLoading(true);
    const q = query(collection(db, `/accounts/${accountID}/clients`), orderBy("created", "desc"));
    const querySnapshot = await getDocs(q);
    let tempRows = [];
    querySnapshot.forEach((doc) => {
      const docData = doc.data()
      tempRows.push({
        id: doc.id,
        firstName: docData.firstName,
        lastName: docData.lastName,
        phone: docData.phone,
        email: docData.email,
        active: docData.active,
        doNotInvoice: docData.doNotInvoice,
        address: docData.customizedAddress ? docData.customizedAddress : docData.address,
        price: `$${docData.price.toFixed(2)}`,
        created: new Date(docData.created.seconds * 1000).toLocaleString(
          "en-US"
        )
      })
    });
    setTableData([...tempRows]);
    setListLoading(false);
  };

  useEffect(() => {
    if (claims?.accountID) {
      void loadDataGrid(claims.accountID);
    }
  }, [claims.accountID]);

  const handleConfirmDelete = async () => {
    const id = rowToDelete
    console.log('delete', id)
    try {
      const clientRef = doc(db, `/accounts/${claims.accountID}/clients/${rowToDelete}`);
      await deleteDoc(clientRef)
      enqueueSnackbar("Successfully Deleted", { variant: "success" });
      setDialogOpen(null);
      apiRef.current.updateRows([{ id, _action: "delete" }]);
      const allIdsNew = apiRef.current.getAllRowIds();
      const allRows = allIdsNew.map((id) => {
        return apiRef.current.getRow(id);
      });
      setTableData(allRows);
    } catch (e){
      enqueueSnackbar("Error occurred while deleting client.", { variant: "error" });
      console.log(e)
    }
  };

  const handleDeleteIconClick = (id) => (event) => {
    event.stopPropagation();
    setRowToDelete(id);
    setDialogOpen(id);
  };

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Box sx={{ displayPrint: "none", display: "flex", columnGap: 2, p:1 }}>
          <GridToolbarExport
            color="primary"
            variant='contained'
            size={"medium"}
          />
        </Box>
      </GridToolbarContainer>
    );
  }

  const columns = [
    {field: "active", headerName: "Active", width: 100, editable: false, type: "boolean"},
    {field: "firstName", headerName: "First Name", width: 150, editable: false,},
    { field: "lastName", headerName: "Last Name", width: 150, editable: false },
    { field: "phone", headerName: "Phone", width: 150, editable: false },
    { field: "email", headerName: "Email", width: 250, editable: false },
    { field: "address", headerName: "Address", width: 250, editable: false },
    { field: "created", headerName: "Created", width: 200, editable: false },
    { field: "price", headerName: "Price", width: 100, editable: false },
    {field: "doNotInvoice", headerName: "Invoicing Disabled", width: 150, editable: false, type: "boolean"},

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteIconClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleRowClick = async (params) => {
    navigate(`/client/${params.id}`);
  };

  return (
    <Box>
      <Box sx={{
        display: "flex",
        justifyContent: 'left',
        columnGap: 2,
        p:3,
        pb:0,
      }}>

      <Button
        color="primary"
        variant={'contained'}
        startIcon={<AddIcon />}
        size={"large"}
        onClick={() => navigate(`/client`)}
      >
        Add Client
      </Button>
      </Box>

      <Box
      sx={{
        p: 3
      }}
    >
      <Box
        sx={{
          height: 1200,
          maxWidth: "100%",
          mx: "auto",
        }}
      >
         <DataGridPro
           density={'compact'}
            apiRef={apiRef}
            editMode="row"
            rows={tableData}
            columns={columns}
            onRowClick={handleRowClick}
            components={{
              Toolbar: EditToolbar,
              LoadingOverlay: LinearProgress,
            }}
            loading={listLoading}

         />
      </Box>
      <Dialog
        open={Boolean(dialogOpen)}
        onClose={() => setDialogOpen(null)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete? If the client has had cuts completed, the recommended practice is to deactivate the client instead.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          id="delete-client-dialog-actions"
        >
          <Button
            autoFocus
            onClick={() => {
              setDialogOpen(null);
              setRowToDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </Box>
  );
}
