import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  Box, TextField,
} from "@mui/material";
import Joi from "joi";

import { doc, getDoc, setDoc} from "firebase/firestore";
import { db } from "../../../firebase-config";
import { useSnackbar } from "notistack";
import {GoogleMapsAutocomplete} from "../../../components/GoogleMapsAutocomplete";
const initialState = {
  address: "",
}

export default function BusinessAddress({claims, disabled}) {
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const addressSchema = Joi.object({
    address: Joi.string().required(),
  });

  useEffect(() => {
    let isMounted = true
    const getBusinessAddress = (accountID) => {
      setLoading(true);
      const docRef = doc(db, `accounts/${accountID}/settings/businessAddress`);
      getDoc(docRef).then((docSnap)=>{
        if (isMounted && docSnap.exists()) {
          setFormData({
            address: docSnap.data().address,
          });
          setLoading(false);

        }
      })
    };
    if (claims?.accountID) {
      getBusinessAddress(claims.accountID);
    }
    return ()=> {
      isMounted = false;
      setFormData(initialState)
      setLoading(true)
      setSaving(false)
    }
  }, [claims.accountID]);

  const handleAddressChange = (value) => {
    setFormData(data => {
        return {...data, "address": value}
      }
    )
    setErrors({});
  }


  const updateBusinessAddress = async () => {
    const docRef = doc(db, `/accounts/${claims.accountID}/settings/businessAddress`);
    await setDoc(docRef, {
      ...formData,
    })
      .then(() => {
        setSaving(false);
        enqueueSnackbar("Save Successful", { variant: "success" });
      })
      .catch((err) => {
        console.log("err", err);
        setSaving(false);
        enqueueSnackbar("Error encountered while updating", { variant: "error" });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = addressSchema.validate(formData);
    const { error } = result;
    setSaving(true);
    if (!error) {
      setErrors({});
      await updateBusinessAddress();
    } else {
      setSaving(false);
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        errorData[name] = item.message;
      }
      setErrors(errorData);
      return errorData;
    }
  };
  return (
    <div>
      <Box>
        {!loading ? (
          <Box
            sx={{
              maxWidth: "800px",
              mx: "auto",
              pt: "25px",
            }}
          >
            <Grid container justifyContent={"center"}>
              <Grid item xs={12} md={8} lg={8}>
                <form onSubmit={handleSubmit}>
                  <Box>
                    <h2>Business Address for Routing</h2>
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    padding={4}
                    columns={1}
                  >
                    <Grid item xs={12}>
                      <GoogleMapsAutocomplete
                        handleAddressChange={handleAddressChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                        label={"Selected Address"}
                        name={`selectedAddress`}
                        id={"selectedAddress"}
                        value={formData.address}
                        InputLabelProps={formData.address ? { shrink: true } : {shrink: false}}
                        fullWidth
                        disabled
                        variant={'standard'}
                        error={Boolean(errors.address)}
                        helperText={errors.address || ""}
                        sx={{
                          px: 1,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={disabled}
                    >
                      Save
                      {saving && <CircularProgress color="primary" size={20} />}
                    </Button>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            padding={4}
            justifyContent="center"
          >
            <CircularProgress color="primary" size={50} />
          </Grid>
        )}
      </Box>
    </div>
  );
}
