import React, {useCallback, useEffect, useState} from "react";
import MUILink from '@mui/material/Link';
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  collection,
  getDoc,
  query,
  updateDoc,
  doc,
  getDocs,
  where,
} from "firebase/firestore";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Select,
  MenuItem, LinearProgress
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import UndoIcon from "@mui/icons-material/Undo";
import { useSnackbar } from "notistack";
import { db } from "../../firebase-config";
import WeekSelector from "../../components/WeekSelector";
import MapIcon from '@mui/icons-material/Map';

const MyOrders = ({claims, user}) => {
  const { enqueueSnackbar } = useSnackbar();
  let navigate = useNavigate();
  const [startWeekDay, setStartWeekDay] = useState(1);
  const [weekDate, setWeekDate] = useState(null);
  const [currentWeek, ] = useState(0);
  const [listLoading, setListLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [employeesLists, setEmployeesLists] = useState(null);
  const [undoID, setUndoID] = useState(null);
  const [orderStatus, setOrderStatus] = useState("remaining");
  const [sortModel, setSortModel] = useState([
    {
      field: 'placement',
      sort: 'asc',
    },
  ]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    cut: false,
    address: true,
    addressMap: true,
    cutDate: false,
    actions: false,
    employees: false,
    placement: true,
  });

  const handleColumnVisibility = useCallback(() => {
    if (orderStatus !== "remaining") {
      setColumnVisibilityModel({
        id: false,
        cut: true,
        address: true,
        addressMap: false,
        cutDate: true,
        actions: true,
        employees: true,
        placement: true,
      });
    } else {
      setColumnVisibilityModel({
        id: false,
        cut: false,
        address: true,
        addressMap: true,
        cutDate: false,
        actions: false,
        employees: false,
        placement: true,
      });
    }
  }, [orderStatus]);

  const getEmployees = async (accountID) => {
    const employeesRef = query(
      collection(db, `accounts/${accountID}/employees/`),
    );
    const employeesDocs = await getDocs(employeesRef);
    const employeeData = [];
    const employeeListPrep = {}
    await employeesDocs.forEach((doc) => {
      employeeData.push({...doc.data(), id: doc.id});
      employeeListPrep[doc.id] = doc.data().firstName + " " + doc.data().lastName;
    })
    setEmployeesLists(employeeListPrep);
    return employeeData
  };


  const loadDataGrid = useCallback(async (accountID, user, orderStatus) => {
    console.log('loadDataGrid');
    setListLoading(true);

    const employeesData = await getEmployees(accountID);
    const userDataArray = employeesData.filter(employee => employee.id === user.uid);
    const userData = userDataArray[0]
    if (userData === undefined) {
      navigate("/");
      return
    }
    const nextDay = new Date(weekDate.getTime() + 60 * 60 * 24 * 1000);
    const prevDay = new Date(weekDate.getTime() - 60 * 60 * 24 * 1000);
    let cut = ""
    if (orderStatus === "remaining") cut = false
    if (orderStatus === "completed") cut = true
    let qRef
    if (cut === "") {
      qRef = query(
        collection(db, `/accounts/${accountID}/individualCuts`),
        where("weekStartDate", "<", nextDay),
        where("weekStartDate", ">", prevDay),
        where("group", "==", userData.group),
      )
    } else {
      qRef = query(
        collection(db, `/accounts/${accountID}/individualCuts`),
        where("weekStartDate", "<", nextDay),
        where("weekStartDate", ">", prevDay),
        where("cut", "==", cut),
        where("group", "==", userData.group),
      );
    }
    const querySnapshot = await getDocs(qRef);
    let allIndividualCutsData = [];
    querySnapshot.forEach((doc) => {
      allIndividualCutsData.push({ id: doc.id, ...doc.data()});
    });
    let tempRows = [];
    for (const individualCut of allIndividualCutsData) {
      const clientRef = doc(db, `accounts/${accountID}/clients/${individualCut.clientID}`);
      const clientDoc = await getDoc(clientRef);
      const clientData = clientDoc.data()
      tempRows.push({
        id: individualCut.id,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        address: clientData.customizedAddress? clientData.customizedAddress : clientData.address,
        addressMap: clientData.address,
        cut:individualCut.cut,
        employees: individualCut.employees,
        cutDate: individualCut.cutDate,
        placement: individualCut.placement,
      })
    }
    setTableData([...tempRows]);
    handleColumnVisibility();
    setListLoading(false);
  },[weekDate, handleColumnVisibility, navigate]);

  useEffect(() => {
    if (weekDate !== null && weekDate !== undefined && claims?.accountID) {
      void loadDataGrid(claims.accountID, user, orderStatus);
    }
  }, [weekDate, claims.accountID, user, orderStatus, loadDataGrid]);


  useEffect(() => {
    const getStartWeekDayDoc = async (accountID) => {
      const docRef = doc(db, `accounts/${accountID}/settings/startDayOfWeek`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setStartWeekDay([
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ].indexOf(docSnap.data().startDayOfWeek));
      }
    };
    if (claims?.accountID) {
        void getStartWeekDayDoc(claims.accountID);
    }
    return () => {
      setStartWeekDay(1);
    };
  }, [claims.accountID]);



  const onWeekSelection = useCallback((dateStartOfWeek) => {
    // passed up from WeekSelector
    setWeekDate(dateStartOfWeek);
  }, [])


  const EditToolbar = () => {
    return (
      <GridToolbarContainer sx={{justifyContent: "center"}}>
      </GridToolbarContainer>
    );
  };


  const handleUndo = (id) => {
    setDialogOpen(true);
    setUndoID(id);
  };

  const handleRevert = async () => {
    let docRef = doc(db, `accounts/${claims.accountID}/individualCuts/${undoID}`);
    await updateDoc(docRef, { cut: false, employees: [], cutDate: null }).then(
      () => {
        enqueueSnackbar("Order Undo", { variant: "success" });
        loadDataGrid(claims.accountID, user, orderStatus);
        setDialogOpen(false);
      }
    );
  };

  const columns = [
    {field: "placement", headerName: "Order", type: "number", width: 75, editable: false},
    {field: "address", headerName: "Address", width: 200, editable: false, renderCell: ({id, row}) => (
        <MUILink component={RouterLink} to={`/order/${id}`}>{row.address}</MUILink>
      )},
    { field: "addressMap", headerName: "Map", headerAlign: "center", width: 125, editable: false, align: "center", renderCell:(params) => {
        return (
          <MUILink href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(params.value)}`}
            target="_blank" rel="noopener noreferrer">
          <MapIcon/></MUILink>
        )
      } },

    {field: "cut", headerName: "Completed", width: 100, editable: false, type: "boolean"},
    {field: "employees", headerName: "Completed By", width: 300, editable: false, renderCell: ({row}) => (
        <>

          {row.employees.map((employee, index) => (
            <Box key={employee} sx={{display: "inline-block", mr: "10px"}}>
              {employeesLists[employee]}{index === row.employees.length - 1 ? "" : ", "}
            </Box>
          ))}
        </>
      )},
    {field: "cutDate", headerName: "Cut Date", width: 200, editable: false, renderCell: ({ row }) => (
        <>
          {row?.cutDate
            ? new Date(row?.cutDate?.seconds * 1000).toLocaleString("en-US")
            : null}
        </>
      ),},
    {field: "actions", type: "actions", headerName: "Actions", width: 100, getActions: ({ id, row }) => {
        return [
          <GridActionsCellItem
            icon={<UndoIcon />}
            label="Delete"
            disabled={currentWeek !== 0 || !row?.cut}
            onClick={() => handleUndo(id)}
            color="inherit"
          />,
        ];
      },},
  ];

  return (
    <Box
      sx={{
        pt: 3,
        pl: .5,
        pr: .5,
      }}
    >
      <WeekSelector startWeekDay={startWeekDay} onChange={onWeekSelection} center={true}/>
      <Box>
        <Select
          name="orderStatus"
          className="status-dropdown"
          required
          value={orderStatus}
          sx={{ mt: "15px", width: 246}}
          onChange={(e) => setOrderStatus(e.target.value)}
        >
          <MenuItem value="all">All Orders</MenuItem>
          <MenuItem value="completed">Completed Orders</MenuItem>
          <MenuItem value="remaining">Remaining Orders</MenuItem>
        </Select>
      </Box>

      <Box
        sx={{
          height: 600,
          maxWidth: "1200px",
          mx: "auto",
          my: "15px",
        }}
      >
        <DataGridPro
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: EditToolbar,
            NoRowsOverlay: () => (
              <Stack
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                No rows to show
              </Stack>
            ),
          }}
          editMode="row"
          rows={tableData}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          loading={listLoading}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        />
      </Box>
      <Dialog
        open={Boolean(dialogOpen)}
        onClose={() => setDialogOpen(null)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reset this order and mark as incomplete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setDialogOpen(null);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleRevert}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyOrders;
