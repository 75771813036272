import React, {useState, useEffect, useCallback, useRef, useMemo} from "react";
import {
  Box,
  Grid,
  LinearProgress,
} from "@mui/material";

import {
  doc,
  collection,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase-config";
import {OptimizeOrder} from "../components/OptimizeOrder";
import {
  DataGridPro,
  gridColumnVisibilityModelSelector,
  GridEvents,
  useGridApiRef
} from "@mui/x-data-grid-pro";
const INITIAL_GROUPING_COLUMN_MODEL = ["group"];

const useKeepGroupingColumnsHidden = (
  apiRef,
  columns,
  initialModel,
  leafField
) => {
  const prevModel = useRef(initialModel);

  useEffect(() => {
    apiRef?.current?.subscribeEvent(
      GridEvents.rowGroupingModelChange,
      (newModel) => {
        const columnVisibilityModel = {
          ...gridColumnVisibilityModelSelector(apiRef),
        };

        newModel.forEach((field) => {
          if (!prevModel.current.includes(field)) {
            columnVisibilityModel[field] = false;
          }
        });
        prevModel.current.forEach((field) => {
          if (!newModel.includes(field)) {
            columnVisibilityModel[field] = true;
          }
        });
        apiRef.current.setColumnVisibilityModel(columnVisibilityModel);
        prevModel.current = newModel;
      }
    );
  }, [apiRef]);

  return useMemo(
    () =>
      columns.map((colDef) =>
        initialModel.includes(colDef.field) ||
        (leafField && colDef.field === leafField)
          ? { ...colDef, hide: true }
          : colDef
      ),
    [columns, initialModel, leafField]
  );
};


export function OptimizeWeeklyOrder({claims}) {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [listLoading, setListLoading] = useState(true);

  const extractDataFromQuery = async (queryRef) => {
    const results = await getDocs(queryRef)
    let resultsData = []
    results.forEach((doc)=> {
      resultsData.push({...doc.data(), id: doc.id})
    })
    return resultsData
  }

  const loadDataGrid = useCallback(async () => {
    setListLoading(true);
    const individualCutsQuery = query(
      collection(db, `/accounts/${claims.accountID}/individualCuts`),
      where("orderListID", "==", id)
    );
    const clientsActiveQuery = query(
      collection(db, `accounts/${claims.accountID}/clients`),
      where("active", "==", true)
    )
    const individualCutsData = await extractDataFromQuery(individualCutsQuery)
    const clientsActiveData = await extractDataFromQuery(clientsActiveQuery)
    let tempRows = [];
    const cutsWithoutClients = [] // missing because not active or deleted
    const clientsActiveObj = {}
    for (const client of clientsActiveData) {
      clientsActiveObj[client.id] = client
    }
    for (const cut of individualCutsData) {
      const exists = Object.hasOwn(clientsActiveObj, cut.clientID)
      if (exists) {
        const clientData = clientsActiveObj[cut.clientID]
        tempRows.push({
          id: cut.id,
          firstName: clientData.firstName,
          lastName: clientData.lastName,
          group: cut.group,
          cut: cut.cut,
          phone: clientData.phone,
          email: clientData.email,
          address: clientData.customizedAddress ? clientData.customizedAddress : clientData.address,
          placement: cut.placement,
          clientID: cut.clientID,
        })
      } else {
        cutsWithoutClients.push(cut)
      }
    }
    const promises = []
    for (const cut of cutsWithoutClients) {
      const docRef = doc(db, `/accounts/${claims.accountID}/clients/${cut.clientID}`);
      promises.push(getDoc(docRef).then((clientDoc) => {
          if (clientDoc.exists()) {
            const data = {
              id: cut.id,
              firstName: clientDoc.data().firstName,
              lastName: clientDoc.data().lastName,
              group: cut.group,
              cut: cut.cut,
              phone: clientDoc.data().phone,
              email: clientDoc.data().email,
              address: clientDoc.data().customizedAddress ? clientDoc.data().customizedAddress : clientDoc.data().address,
              placement: cut.placement,
              clientID: cut.clientID,
            };
            tempRows.push(data);
          } else {
            const data = {
              id: cut.id,
              firstName: "Deleted",
              lastName: "Deleted",
              group: cut.group,
              cut: cut.cut,
              phone: "Deleted",
              email: "Deleted",
              address: "Deleted",
              placement: cut.placement,
              clientID: cut.clientID,
            };
            tempRows.push(data);
          }
        }
      ))
    }
    await Promise.all(promises)
    setTableData([...tempRows]);
    setListLoading(false);
  },[id, claims.accountID])


  useEffect( () => {
    if (id && claims?.accountID) {
      void loadDataGrid();
    } else {
      setListLoading(false);
    }
  }, [id, claims.accountID, navigate, loadDataGrid]);


  const columnsData = [
    { field: "placement", headerName: "Order", width: 60,  align: "center" },
    { field: "address", headerName: "Address", width: 200},
    { field: "firstName", headerName: "First Name", width: 125},
    { field: "lastName", headerName: "Last Name", width: 125},
    { field: "cut", headerName: "Cut Status",  width: 150,   type: "boolean" },
    { field: "group",  headerName: "Group",  width: 75},
  ]

  const columns = useKeepGroupingColumnsHidden(
    apiRef,
    columnsData,
    INITIAL_GROUPING_COLUMN_MODEL
  );


  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={9}>
          <Box
            sx={{
              p: 3
            }}
          >
            <Box
              sx={{
                height: 1200,
                maxWidth: "100%",
                mx: "auto",
              }}
            >

              <DataGridPro
                rows={tableData}
                apiRef={apiRef}
                columns={columns}
                initialState={{
                  rowGrouping: {
                    model: INITIAL_GROUPING_COLUMN_MODEL,
                  },
                }}
                experimentalFeatures={{
                  rowGrouping: true,
                }}
                loading={listLoading}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                hideFooterRowCount={true}
              />
            </Box>
          </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3}>
        <Box
          mt={{
            xs: 3,
            sm: 3,
            md: 3,
            lg: 3,
          }}
          mr={{
            xs: 3,
            sm: 3,
            md: 3,
            lg: 3,
          }}
          ml={{
            xs: 3,
            sm: 3,
            md: 3,
            lg: 0,
          }}
        >
          <OptimizeOrder
            claims={claims}
            tableData={tableData}
            orderListID={id}
          />

        </Box>
      </Grid>
    </Grid>
  );
}
