import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Paper,
} from "@mui/material";
import { db } from "../firebase-config";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  addDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import Joi from "joi";
import { useSnackbar } from "notistack";

export default function ClientNotes({ claims, clientID, user }) {
  const [tableData, setTableData] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [formData, setFormData] = useState({
    note: "",
    userID: user.uid,
    userEmail: user.email,
  });
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const noteSchema = Joi.object({
    note: Joi.string().required(),
  });

  const loadDataGrid = async (accountID, clientID) => {
    setListLoading(true);
    const q = query(
      collection(db, `/accounts/${accountID}/clients/${clientID}/notes`),
      orderBy("created", "desc")
    );
    if (accountID !== undefined && clientID !== undefined) {
      const querySnapshot = await getDocs(q);
      let tempRows = [];
      querySnapshot.forEach((doc) => {
        const data = {
          id: doc.id,
          note: doc.data().note,
          userEmail: doc.data().userEmail || "",
          created: new Date(doc.data().created.seconds * 1000).toLocaleString(
            "en-US"
          ),
        };
        tempRows.push(data);
      });
      setTableData([...tempRows]);
    }
    setListLoading(false);
  };

  useEffect(() => {
    if (clientID && claims?.accountID) {
      void loadDataGrid(claims.accountID, clientID);
    }
    return () => {
      setListLoading(true)
      setTableData([])
    }
  }, [claims.accountID, clientID]);


  const createNewNote = async () => {
    const colRef = collection( db, `/accounts/${claims.accountID}/clients/${clientID}/notes`)
    await addDoc(colRef, {
      created: serverTimestamp(),
      note: formData.note,
      userID: user.uid,
      userEmail: user.email,
      accountID: claims.accountID,
      clientID: clientID,
    })
      .then(() => {
        setSaving(false);
        enqueueSnackbar("Note added", { variant: "success" });
        setFormData({
          note: "",
          userID: user.uid,
        });
        loadDataGrid(claims.accountID, clientID)
      })
      .catch((err) => {
        console.log("err", err);
        setSaving(false);
        enqueueSnackbar("Error in Note creation", { variant: "error" });
      });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = noteSchema.validate({ note: formData.note });
    const { error } = result;
    setSaving(true);
    if (!error) {
      setErrors({});
      await createNewNote();
    } else {
      setSaving(false);
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        errorData[name] = item.message;
      }
      setErrors(errorData);
      return errorData;
    }
  };

  const handleChange = (value, field) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleConfirmDelete = async () => {
    const docRef = doc(
      db,
      `/accounts/${claims.accountID}/clients/${clientID}/notes/${rowToDelete}`
    );
    await deleteDoc(docRef);
    enqueueSnackbar("Successfully Deleted", { variant: "success" });
    loadDataGrid(claims.accountID, clientID);
    setDialogOpen(null);
    setFormData({
      note: "",
      userID: user.uid,
    })

  };

  const handleDeleteIconClick = (id) => {
    setRowToDelete(id);
    setDialogOpen(id);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        mx: {sm: "auto", md: 4, lg: "auto", xl: "auto"},
        pt: 2,
        pb: 3,
      }}
    >
      <Box>
        <h2>
          Notes
        </h2>
      </Box>

      <Box>
        <Box sx={{
          display: "flex",
          pt: 2,
          px: {xs:2 , sm: 2, md: 3, lg: 4, xl: 5},
          pb: 3
        }}>
          <TextField
            id="note-create"
            label="Type Something"
            required
            multiline
            name="note"
            value={formData.note}
            placeholder="Type Something"
            onChange={(e) => handleChange(e.target.value, "note")}
            error={Boolean(errors.note)}
            helperText={errors.note || ""}
            fullWidth
            disabled={saving}
          />
          <Button
            id="note-create-button"
            variant="contained"
            aria-label="Submit Note"
            disabled={saving}
            sx={{
              ml: "10px",
            }}
            onClick={handleSubmit}
          >
            <SendIcon fontSize="medium"/>
          </Button>
        </Box>
        <Divider />

        {listLoading && (
          <CircularProgress />
        )}
        {!listLoading && !tableData.length && (
          <Typography variant="subtitle1" textAlign="center" paddingTop={2}>
            No records found
          </Typography>
        )}
        <List sx={{
          pt: 3,
          px: {xs:2 , sm: 2, md: 3, lg: 4, xl: 5},
          display: "flex",
          flexDirection: "column",
          rowGap: 3,
        }}
        >
          {tableData.map((item, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  aria-label="Delete Note"
                  onClick={() => handleDeleteIconClick(item.id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
              sx={{
                backgroundColor: "grey.100",
              }}
            >
              <ListItemText
                align="left"
                secondary={`${item.created} - ${item.userEmail}`}
              >{item.note}</ListItemText>
            </ListItem>
          ))}
        </List>

        <Dialog
          open={Boolean(dialogOpen)}
          onClose={() => setDialogOpen(null)}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this note?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                setDialogOpen(null);
                setRowToDelete(null);
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete}>Delete</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
};

